import {ua} from './libs/daijima/ua';

export default class {
  constructor(target, targetPackageName) {
    this.init(target, targetPackageName);
    return this.obj;
  }

  getTargetPackage() {
    return this.obj;
  }

  init(target, targetPackageName) {
    let obj = target[targetPackageName] = target[targetPackageName] || {};
    this.obj = obj;
    obj.BP = 1024;
    obj.ua = ua;
    obj.hasTouch = ("ontouchstart" in window);
    obj.pageYOffset = 0;
    obj.pastPageYOffset = 0;
    obj.scrollWrapper = document.querySelector('.scroll-wrapper');

    obj.CONTENTFUL_API_SPACES = "rfp71c5fx4wl";        //本番用
    obj.CONTENTFUL_API_ACCESS_TOKEN = "6-DHAaclPcOiy0L_ew2zRrk1UHMKWCwjNb7bUjSFcqg";        //本番用

    obj.CONTENTFUL_API_BRANCH = "master";   //本番用
    // obj.CONTENTFUL_API_BRANCH_DEV = "master-2023-03-07";   //開発用

    obj.CONTENTFUL_API_PATH_LIST = [
      "https://cdn.contentful.com/spaces/" + obj.CONTENTFUL_API_SPACES + "/environments/" + obj.CONTENTFUL_API_BRANCH + "/entries?content_type=general&access_token=" + obj.CONTENTFUL_API_ACCESS_TOKEN,                                  //各種カウント
      // "https://cdn.contentful.com/spaces/" + obj.CONTENTFUL_API_SPACES + "/environments/" + obj.CONTENTFUL_API_BRANCH + "/entries?content_type=people&access_token=" + obj.CONTENTFUL_API_ACCESS_TOKEN,                                //People
      // "https://cdn.contentful.com/spaces/" + obj.CONTENTFUL_API_SPACES + "/environments/" + obj.CONTENTFUL_API_BRANCH + "/entries?content_type=newsEntry&access_token=" + obj.CONTENTFUL_API_ACCESS_TOKEN,                                //News
      // "https://cdn.contentful.com/spaces/" + obj.CONTENTFUL_API_SPACES + "/environments/" + obj.CONTENTFUL_API_BRANCH + "/entries?content_type=documentEntry&access_token=" + obj.CONTENTFUL_API_ACCESS_TOKEN,                            //Document
      "https://cdn.contentful.com/spaces/" + obj.CONTENTFUL_API_SPACES + "/environments/" + obj.CONTENTFUL_API_BRANCH + "/entries?content_type=careerPositionCategory&access_token=" + obj.CONTENTFUL_API_ACCESS_TOKEN,                   //Career Divisions
      // "https://cdn.contentful.com/spaces/" + obj.CONTENTFUL_API_SPACES + "/environments/" + obj.CONTENTFUL_API_BRANCH + "/entries?content_type=techBlog&access_token=" + obj.CONTENTFUL_API_ACCESS_TOKEN,                                 //Tech Blog
      "https://cdn.contentful.com/spaces/" + obj.CONTENTFUL_API_SPACES + "/environments/" + obj.CONTENTFUL_API_BRANCH + "/entries?content_type=contactList&access_token=" + obj.CONTENTFUL_API_ACCESS_TOKEN,                          //Contact list
      "https://cdn.contentful.com/spaces/" + obj.CONTENTFUL_API_SPACES + "/environments/" + obj.CONTENTFUL_API_BRANCH + "/entries?content_type=piackUp&limit=5&access_token=" + obj.CONTENTFUL_API_ACCESS_TOKEN,                      //Pick up
      "https://cdn.contentful.com/spaces/" + obj.CONTENTFUL_API_SPACES + "/environments/" + obj.CONTENTFUL_API_BRANCH + "/entries?content_type=lifeAtTierIv&limit=7&access_token=" + obj.CONTENTFUL_API_ACCESS_TOKEN,                 //Life at tier iv
      "https://cdn.contentful.com/spaces/" + obj.CONTENTFUL_API_SPACES + "/environments/" + obj.CONTENTFUL_API_BRANCH + "/entries?content_type=media&limit=200&order=-fields.releaseDate&access_token=" + obj.CONTENTFUL_API_ACCESS_TOKEN,      //Media
      "https://cdn.contentful.com/spaces/" + obj.CONTENTFUL_API_SPACES + "/environments/" + obj.CONTENTFUL_API_BRANCH + "/entries?content_type=mediaCategory&order=fields.displayOrder&access_token=" + obj.CONTENTFUL_API_ACCESS_TOKEN,                        //Media categories
    ];
    obj.CONTENTFUL_API_NAME_LIST = ['counts', 'career', 'contact_list', 'pickup', 'life_at_tier_4', 'media', 'media_categories'];

    // obj.MEDIUM_RSS_FEED = "https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@tieriv";

    obj.getContentfulAPICareerPath = (id) => {
      return `https://cdn.contentful.com/spaces/` + obj.CONTENTFUL_API_SPACES + `/environments/` + obj.CONTENTFUL_API_BRANCH + `/entries?content_type=careerPositionEntry&fields.category.sys.contentType.sys.id=careerPositionCategory&fields.category.sys.id=${id}&access_token=` + obj.CONTENTFUL_API_ACCESS_TOKEN;
    };

    obj.getContentfulAPIMediaDetailPath = (id) => {
      return `https://cdn.contentful.com/spaces/` + obj.CONTENTFUL_API_SPACES + `/environments/` + obj.CONTENTFUL_API_BRANCH + `/entries?content_type=media&sys.id=${id}&access_token=` + obj.CONTENTFUL_API_ACCESS_TOKEN;
    };

    obj.getContentfulAPIImgAccessPath = (id) => {
      return `https://cdn.contentful.com/spaces/` + obj.CONTENTFUL_API_SPACES + `/environments/` + obj.CONTENTFUL_API_BRANCH + `/assets/${id}?access_token=` + obj.CONTENTFUL_API_ACCESS_TOKEN;
    };

    obj.contentfulJSONList;
    obj.LANG = document.documentElement.lang;        //ja, en or zh-cmn-Hans
    if (obj.LANG === "zh-cmn-Hans") obj.LANG = "cn";

    obj.getName = (value) => {
      let name;
      if (obj.LANG === "en") {
        name = value.nameEn;
      } else if (obj.LANG === "ja") {
        name = value.nameJa;
      } else {
        name = value.nameCn;
      }
      return name;
    };

    obj.getLocation = (value) => {
      let location;
      if (obj.LANG === "en") {
        location = value.locationEn;
      } else if (obj.LANG === "ja") {
        location = value.locationJa;
      } else {
        location = value.locationCn;
      }
      return location;
    };

    obj.getPosition = (value) => {
      let position;
      if (obj.LANG === "en") {
        position = value.postionEn;
      } else if (obj.LANG === "ja") {
        position = value.postionJa;
      } else {
        position = value.postionCn;
      }
      return position;
    };

    obj.getTitle = (value) => {
      let title;
      if (obj.LANG === "en") {
        title = value.titleEn;
      } else if (obj.LANG === "ja") {
        title = value.titleJa;
      } else {
        title = value.titleCn;
      }
      return title;
    };

    obj.getBody = (value) => {
      let body;
      if (obj.LANG === "en") {
        body = value.bodyEn;
      } else if (obj.LANG === "ja") {
        body = value.bodyJa;
      } else {
        body = value.bodyCn;
      }
      return body;
    };

    obj.getData = (value, fieldName) => {
      let data;
      if (obj.LANG === "en") {
        data = value[fieldName + 'En'];
      } else if (obj.LANG === "ja") {
        data = value[fieldName + 'Ja'];
      } else {
        data = value[fieldName + 'Cn'];
      }
      return data;
    };

    //mediaここから
    obj.mediaAllList = [];

    obj.setMediaValues = (value, cb, sc) => {
      let media = value.items;
      let currentTotalID = 0;
      for (let i = 0, len = media.length; i < len; i++) {
        let n = media[i]
        let item = n.fields;
        let body = obj.getBody(item);
        if (body) {
          let sysID = n.sys.id;
          let thumbnail = item.thumbnail;
          let thumbnailAccessAPIPath = undefined;
          if (thumbnail) thumbnailAccessAPIPath = obj.getContentfulAPIImgAccessPath(thumbnail.sys.id);

          let date = obj.getFormatDate(item.releaseDate);
          let originalDate = item.releaseDate;
          let title = obj.getTitle(item);
          let link = item.mediumUrl;
          let category = obj.mediaCategories.find((u) => u.id === item.category.sys.id).name;
          let categoryID = item.category.sys.id;

          let data = {
            date: date,
            originalDate: originalDate,
            title: title,
            category: category,
            categoryID: categoryID,
            thumbnailAccessAPIPath: thumbnailAccessAPIPath,
            sys_id: sysID,
            link: link
          };
          obj.mediaAllList.push(data);
        }
      }

      //日付新しい順でソート
      obj.mediaAllList.sort(function (a, b) {
        if (a.originalDate > b.originalDate) return -1;
        if (a.originalDate < b.originalDate) return 1;
        return 0;
      });

      // trace(media);
      let total = value.total;
      let skip = value.skip;
      let current = skip + media.length;
      if(current < total) obj.loadMoreMedia(current, cb, sc);
      else cb.call(sc);
    };

    obj.loadMoreMedia = (current, cb, sc) => {
      let url = obj.CONTENTFUL_API_PATH_LIST[5] + '&skip=' + current;
      fetch(url).then ((res) => {
        return res.json();
      }).then( (res) => {
        // trace(res);
        obj.setMediaValues(res, cb, sc);
      })
    };

    obj.loadMediaArticleThumbnail = (item, dom) => {
      let url = item.thumbnailAccessAPIPath;
      fetch(url).then((res) => {
        return res.json();
      }).then((res) => {
        if(res.fields){
          let protocol = location.protocol;
          let thumbURL = protocol + res.fields.file.url;
          let img = dom.querySelector("img");
          img.src = thumbURL;
        }
      });
    };

    obj.loadMediaArticleThumbnails = (urlList, thumbnailPathList, startID, endID, callback, scope) => {
      let promiseList = [];

      for (let i = startID; i < endID; i++) {
        let url = urlList[i].thumbnailAccessAPIPath;
        if (url) {
          promiseList.push(
            fetch(url).then((res) => {
              return res.json();
            }).then((res) => {
              thumbnailPathList[i] = res;
            })
          );
        } else {
          thumbnailPathList[i] = undefined;
        }
      }

      Promise
        .all(promiseList)
        .then(() => {
          // trace("media thumbnail path loaded", thumbnailPathList);
          callback.call(scope, startID, endID, thumbnailPathList);
        });
    };
    //mediaここまで

    //media categoriesここから
    obj.mediaCategories = [];
    obj.setMediaCategories = () => {
      let cates = obj.contentfulJSONList['media_categories'].items;

      for( let i = 0, len = cates.length; i < len; i++ ){
        let cate = cates[i];
        let data = {};
        data.name = obj.getName(cate.fields);
        data.id = cate.sys.id;
        obj.mediaCategories[i] = data;
      }
    };

    //media categoriesここまで

    //pickupここから
    obj.pickupList = [];
    obj.setPickupValues = () => {
      // let pickup = obj.contentfulJSONList[7].items;
      let pickup = obj.contentfulJSONList['pickup'].items;
      for (let i = 0, len = pickup.length; i < len; i++) {
        let item = pickup[i].fields;
        let thumbnail = item.image;
        let thumbnailAccessAPIPath = undefined;
        if (thumbnail) thumbnailAccessAPIPath = obj.getContentfulAPIImgAccessPath(thumbnail.sys.id);
        let date = obj.getFormatDate(item.date);
        let title = obj.getData(item, 'title');
        let category = obj.getData(item, 'category');
        let url = obj.getData(item, 'url');
        let data = {
          date: date,
          title: title,
          category: category,
          link: url,
          thumbnail: thumbnail,
          thumbnailAccessAPIPath: thumbnailAccessAPIPath
        };
        obj.pickupList.push(data);
      }
    };
    //pickupここまで

    obj.changeLang = (value) => {
      let href;
      if (obj.LANG === "en") href = location.href.split("/en")[1];
      if (obj.LANG === "cn") href = location.href.split("/cn")[1];
      else if (obj.LANG === "ja") href = location.href;

      if (value === "en") href = "/en" + href;
      else if (value === "cn") href = "/cn" + href;
      location.href = href;
    };

    obj.setLocalStorage = (value) => {
      // trace(value);
      // document.cookie = 'lang=' + value;
      localStorage.setItem('lang', value);
    }

    obj.setSessionStorage = (value) => {
      sessionStorage.setItem('lang', value);
    }

    obj.getFormatDate = (date) => {
      let dateArray = String(new Date(date)).split(" ");
      return dateArray[1] + " " + dateArray[2] + ", " + dateArray[3];
    };

    // 3桁カンマ区切りとする
    obj.addComma = (num) => {
      var s = String(num).split('.');
      var ret = String(s[0]).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
      if (s.length > 1) {
        ret += '.' + s[1];
      }
      return ret;
    };

    obj.d2r = function (d) {
      return d * Math.PI / 180;
    };

    obj.r2d = function (r) {
      return r * 180 / Math.PI;
    };

    obj.random = function (min, max) {
      return Math.random() * (max - min) + min;
    };

    obj.checkBG = (target) => {
      if (!obj.background) return;
      if (target.getBoundingClientRect().top <= obj.common.sh) {
        obj.background.changeToFooter();
      } else {
        obj.background.changeToNormal();
      }
    };

    obj.checkBottom = () => {
      return (obj.pageYOffset + 1) >= (document.body.clientHeight - obj.common.sh);
    };

    obj.lockHTML = () => {
      let html = document.documentElement;
      html.classList.add("hidden");
    };

    obj.unlockHTML = () => {
      let html = document.documentElement;
      html.classList.remove("hidden");
    };

    obj.anchorScroll = function (targetY, dr, ease, cb) {
      if (!dr) dr = 1;
      if (!ease) ease = Quart.easeInOut;
      let obj = {y: window.pageYOffset};
      TweenMax.killTweensOf(obj);

      TweenMax.to(obj, dr, {
        y: targetY, ease: ease, onUpdate: function () {
          window.scrollTo(0, obj.y);
        }, onComplete: function () {
          if (cb) cb();
        }
      });
    };

    obj.commonParallax = function (item, targetY, defY, addCode, moveY) {
      let top = item.getBoundingClientRect().top;
      if (defY === undefined) defY = 200;

      let speed = (item.dataset.speed != "undefined") ? item.dataset.speed : "5";
      let difY = (top - targetY) / speed + defY;  //スタート地点はデフォルトでは200px下げる
      if (addCode == undefined) addCode = '';

      if (moveY) {
        if (difY < defY - moveY) difY = defY - moveY;
      }

      if (top < targetY) {
        item.style.transform = 'translate3d(0,' + difY + 'px, 0)' + addCode;
        // item.style.top = difY + 'px';
      }
    };

    obj.addZero = function (str) {
      if (str.length == 1) str = "0" + str;
      return str;
    };
  }
}
