import TemplateContents from './TemplateContents';
import Carousel from "./Carousel";

export default class extends Carousel {
  constructor(param) {
    super(param);
  }

  setVars(){
    super.setVars();
    this.current = 0;
  }

  setDom(){
    super.setDom();

    this.circles = document.querySelectorAll('main.ourteam > .section7 .section_child .ui .circle');
  }

  enterframeThinOut() {
    let current, singleW;

    if(this.sw >= this.pack.BP) singleW = this.sw * .5;
    else singleW = this.sw * .944;

    current = Math.abs(Math.round(this.swipeX / singleW));

    if(current != this.current){
      this.carouselChilds[this.current].classList.remove('current');
      this.circles[this.current].classList.remove('current');
      this.carouselChilds[current].classList.add('current');
      this.circles[current].classList.add('current');
      this.current = current;
    }
  }
}
