import TemplateContents from './TemplateContents';

export default class extends TemplateContents{
    constructor(param){
        super(param);
    }

    init() {
        super.init();

        this.pack.common.addScrollTarget(this);
        // this.pack.common.addEnterframeTarget(this);
    }

    reset(){
        super.reset();

        this.setVars();
        this.pack.common.addScrollTarget(this);
        // this.pack.common.addEnterframeTarget(this);
    }

    destruct(){
        super.destruct();

        this.loadMore.removeEventListener("click", this.bindClickLoadMoreHandler);

        this.pack.common.removeScrollTarget(this);
        // this.pack.common.removeEnterframeTarget(this);
    }

    destructAfterContentsOut(){
        this.next.classList.remove("simple");
        this.footerDOM.classList.remove("simple");
    }

    setVars(){
        super.setVars();

        this.currentDisplayedLen = 0;
        this.currentCategory = "ALL CATEGORY";
    }

    setDom(){
        super.setDom();

        this.next = document.querySelector('.next');
        this.footerDOM = document.querySelector('footer');

        this.tit = document.querySelector('main.media > .kv .kv_child h1 .tit');
        this.num = document.querySelector('main.media > .kv .kv_child h1 .number');
        this.select = document.querySelector('main.media > .kv .kv_child .filter select');

        this.articleContainer = document.querySelector('main.media > .section1 .section_child');

        this.loadMore = document.querySelector('main.media > .section1 .load');
    }

    initEvents(){
        super.initEvents();

        this.bindChangeHandler = this.changeHandler.bind(this);
        this.bindClickLoadMoreHandler = this.clickLoadMoreHandler.bind(this);
        this.select.addEventListener("change", this.bindChangeHandler);

        this.loadMore.addEventListener("click", this.bindClickLoadMoreHandler);
    }

    clickLoadMoreHandler(event){
        this.setArticles();
    }

    changeHandler(event){
        let value = event.currentTarget.value;
        this.currentCategory = value;

        this.setLabel();

        this.currentDisplayedLen = 0;
        this.articleContainer.innerHTML = "";

        this.setArticles();
        // trace(value, this.tit.innerText, this.num.innerText);

        this.setPushState(value);
    }

    setPushState(value){
      let newUrl;
      let url = location.origin + location.pathname;
      if(value === 'ALL CATEGORY') newUrl = url;
      else newUrl = url + '?cate=' + value;
      history.pushState({}, '', newUrl);
    }

    getURLParam(v){
        if(window.location.search.length > 1){
            var query = window.location.search.substring(1);
            var parameters = query.split( '&' );
            var r;

            for( var i = 0; i < parameters.length; i++ )
            {
                var element = parameters[ i ].split( '=' );
                var paramName = decodeURIComponent( element[ 0 ] );
                var paramValue = decodeURIComponent( element[ 1 ] );

                if(paramName === v) r = paramValue;
            }

            return r;
        }
    }

    start(){
        super.start();
        let cate = this.getURLParam("cate");
        // if(cate === "BLOG") this.currentCategory = cate;
        if(cate) this.currentCategory = cate;

        this.next.classList.add("simple");
        this.footerDOM.classList.add("simple");

        this.setLabel();
        this.setArticles();
        this.setCategorySelect();

        if(cate) this.setSelect();
    }

    setCategorySelect(){
      let categories = this.pack.mediaCategories;
      for( let i = 0, len = categories.length; i < len; i++ ){
        let category = categories[i].name;
        let option = document.createElement("option");
        option.text = category;
        option.value = category;
        this.select.appendChild(option);
      }
    }

    setSelect(){
        let options = this.select.querySelectorAll("option");
        for( let i = 0, len = options.length; i < len; i++ ){
            let item = options[i];
            item.selected = false;
            if(item.value === this.currentCategory) item.selected = true;
        }
    }

    setLabel(){
        let label = this.currentCategory;
        if(this.currentCategory === "ALL CATEGORY") label = "ALL";

        this.tit.innerText = label;
    }

    setArticles(){
        let len, list;

        if(this.currentCategory === "ALL CATEGORY") list = this.pack.mediaAllList;
        else {
          list = [];
          for( let i = 0, len = this.pack.mediaAllList.length; i < len; i++ ){
            let item = this.pack.mediaAllList[i];
            if(item.category === this.currentCategory) list.push(item);
          }
        }

        this.num.innerText = list.length;

        if(this.currentDisplayedLen === 0) len = 11;
        else len = 10;
        let pastLen = this.currentDisplayedLen;
        this.currentDisplayedLen += len;
        if(this.currentDisplayedLen >= list.length) {
            this.currentDisplayedLen = list.length;
            this.loadMore.classList.add("hide");
        }else{
            this.loadMore.classList.remove("hide");
        }

        for( let i = pastLen; i < this.currentDisplayedLen; i++ ){
            let item = list[i];
            if(item){
                let dom = this.getItem(item.title, item.date, item.category, item.thumbnail, item.link, item.sys_id);
                this.articleContainer.appendChild(dom);
            }
        }

        this.thumbnailPathList = [];
        this.pack.loadMediaArticleThumbnails(list, this.thumbnailPathList, pastLen, this.currentDisplayedLen, this.setThumbnails, this);
    }


    getItem(title, date, category, thumbnail, link, sys_id){
        /* pug src
        article
            a(href="")
                .thumb
                    img(src="/assets/img/media/default.jpg" alt="")
                p.txt
                    | More the vihicle drives, safer & smarter the system evolves.
                p.info
                    span.date APR 1, 2021
                    span.category TECH BLOG
         */

        if(!thumbnail) thumbnail = "/assets/img/media/default.jpg";
        let target = "_self";

        if(!link) {
            if(sys_id) {
                let lang;
                if(this.pack.LANG === "ja") lang = "/";
                else lang = "/" + this.pack.LANG + "/";
                link = lang + "media/detail/?sys_id=" + sys_id + "&category=" + category;
            }else link = "";
        }else{
            target = "_blank";
        }

        let item = document.createElement("article");
        let src = `
            <a href="${link}" target="${target}">
                <div class="thumb"><img src="${thumbnail}" alt="" loading="lazy"></div>
                <p class="txt font2">${title}</p>
                <p class="info"><span class="date">${date}</span><span class="category">${category}</span></p>
            </a>
        `;
        item.innerHTML = src;

        return item;
    }

    setThumbnails(startID, endID, thumbnailPathList){
        let protocol = location.protocol;
        let imgs = document.querySelectorAll('main.media > .section1 .section_child article .thumb img');

        for( let i = startID; i < endID; i++ ){
            let thumbPath = thumbnailPathList[i];
            if(thumbPath.fields){
                let thumbURL = protocol + thumbPath.fields.file.url;
                imgs[i].src = thumbURL;
            }
        }
    }

    scrollHandler(){
        this.pack.checkBG(this.next);
    }


    enterframe(){

    }

    enterframeThinOut(){

    }

    executeResize() {
        super.executeResize();
    }
}
