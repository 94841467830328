import TemplateContents from './TemplateContents';

export default class extends TemplateContents{
  constructor(param){
    super(param);
  }

  init() {
    super.init();

    // this.pack.common.addScrollTarget(this);
    // this.pack.common.addEnterframeTarget(this);
  }

  reset(){
    super.reset();

    this.setVars();
    // this.pack.common.addScrollTarget(this);
    // this.pack.common.addEnterframeTarget(this);
  }

  destruct(){
    super.destruct();

    // this.pack.common.removeScrollTarget(this);
    // this.pack.common.removeEnterframeTarget(this);
  }

  destructAfterContentsOut(){
    this.footerDOM.classList.remove("simple");
  }

  setVars(){
    super.setVars();
  }

  setDom(){
    super.setDom();

    this.footerDOM = document.querySelector('footer');
    this.container = document.querySelector('main.contact ul.contacts');
  }

  initEvents(){
    super.initEvents();
  }

  start(){
    this.footerDOM.classList.add("simple");
    this.setValues();
  }

  setValues() {
    // let values = this.pack.contentfulJSONList[6].items;
    let values = this.pack.contentfulJSONList['contact_list'].items;
    let html = '';
    for( let i = 0, len = values.length; i < len; i++ ){
      let value = values[i].fields;
      let mailAddress = this.pack.getData(value, 'mailAddress');
      let title = this.pack.getData(value, 'title');
      html += this.getHTML(mailAddress, title);
    }
    this.container.innerHTML = html;
  }

  getHTML(mailAddress, title){
    /*
    li.item
      div.tit New Business
      div.address
        a(href="mailto:sales@tier4.jp") sales@tier4.jp
     */

    return `
      <li class="item">
        <div class="tit">${title}</div>
        <div class="address">
          <a href="mailto:${mailAddress}">${mailAddress}</a>
        </div>
      </li>
    `;
  }

  scrollHandler(){

  }


  enterframe(){

  }

  enterframeThinOut(){

  }

  executeResize() {
    super.executeResize();
  }
}
