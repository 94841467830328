import TemplateContents from './TemplateContents';

export default class extends TemplateContents{
    constructor(param){
        super(param);
    }

    init() {
        super.init();

        this.pack.common.addScrollTarget(this);
        // this.pack.common.addEnterframeTarget(this);
    }

    reset(){
        super.reset();

        this.setVars();
        this.pack.common.addScrollTarget(this);
        // this.pack.common.addEnterframeTarget(this);
    }

    destruct(){
        super.destruct();

        document.body.classList.remove("no_hidden");
        document.body.classList.remove("no_hidden_sp");

        this.pack.common.removeScrollTarget(this);
        // this.pack.common.removeEnterframeTarget(this);
    }

    setVars(){
        super.setVars();
    }

    setDom(){
        super.setDom();

        this.next = document.querySelector('.next');
        this.hero = document.querySelector("main.opensource #hero");
        this.heroBGWrap = document.querySelector('main.opensource #hero .hero_child .img');
        this.heroCircle = document.querySelector('main.opensource #hero #clip01 circle');
        this.heroViewSVG = document.querySelector('main.opensource #hero .hero_child .img svg.view_svg');

        this.section1Count = document.querySelectorAll('main.opensource .section1 .section_child ul li .chunk .txt .cnt');

        this.section2Chunks = document.querySelectorAll('main.opensource .section_chunk > section .section_child .cont .right .chunk');
        this.section2Imgs = document.querySelectorAll('main.opensource .section_chunk .section2 .section_child .cont .left img');
        this.section2StickyTarget = document.querySelector('main.opensource .section_chunk .section2 .section_child .cont .left');

        this.section4 = document.querySelector('main.opensource .section4');
    }

    initEvents(){
        super.initEvents();
    }

    start(){
        super.start();
        document.body.classList.add("no_hidden");
        document.body.classList.add("no_hidden_sp");
        this.setValues();
    }

    setValues(){
        // let values = this.pack.contentfulJSONList[0].items[0].fields;
        let values = this.pack.contentfulJSONList['counts'].items[0].fields;
        this.section1Count[0].innerText = this.pack.addComma(values.vehicles_count);
        this.section1Count[1].innerText = this.pack.addComma(values.countries_count);
        this.section1Count[2].innerText = this.pack.addComma(values.companies_count);
    }

    scrollHandler(){
        let target = this.next;
        let bg = this.pack.background;
        if(!bg) return;
        let top = target.getBoundingClientRect().top;
        let heroTop = this.hero.getBoundingClientRect().top;
        if(top <= this.sh){
            bg.changeToFooter();
        }else if( heroTop < this.sh && heroTop >= -this.sh){
            bg.changeToFooter();
        }else{
            bg.changeToNormal();
        }

        this.checkHero();
        this.checkSection2();
        this.checkSection4();

        this.pack.checkBottom() ? this.next.classList.add("show_fixed") : this.next.classList.remove("show_fixed");
    }

    checkHero(){
        if(this.pack.pageYOffset <= this.sh){
            this.sw = window.innerWidth;
            this.sh = window.innerHeight;
            this.swh = this.sw / 2;
            this.shh = this.sh / 2;

            let normal = this.pack.pageYOffset / this.sh;
            let r;
            if(this.sw >= this.pack.BP) r = 217;
            else r = 121;
            let current = this.sw;
            this.heroCircle.style.r = r + normal * current + "px";
            this.heroViewSVG.setAttribute("viewBox", "0,0," + this.sw + "," + this.sh);
            this.heroBGWrap.style.height = this.sh + "px";
        }
    }

    checkSection2(){
        let current = -1;
        for( let i = 0, len = this.section2Chunks.length; i < len; i++ ){
            let item = this.section2Chunks[i];
            if(item.getBoundingClientRect().top < this.sh - 200){
                current = i;
            }
        }

        if(current < 0) return;
        this.resizeHandler();

        for( let i = 0, len = this.section2Imgs.length; i < len; i++ ){
            let item = this.section2Imgs[i];
            let chunk = this.section2Chunks[i];
            item.classList.remove("show");
            chunk.classList.remove("clearify");
        }

        this.section2Imgs[current].classList.add("show");
        this.section2Chunks[current].classList.add("clearify");
    }

    checkSection4(){
        let section4Y = this.section4.getBoundingClientRect().top;

        if(section4Y <= this.sh){
            if(!this.section4.classList.contains("show")){
                this.section4.classList.add("show")
            }
        }else{
            if(this.section4.classList.contains("show")){
                this.section4.classList.remove("show")
            }
        }
    }

    enterframe(){

    }

    enterframeThinOut(){

    }

    executeResize() {
        super.executeResize();
        this.heroBGWrap.style.height = this.sh + "px";
        this.heroViewSVG.setAttribute("viewBox", "0,0," + this.sw + "," + this.sh);
        this.section2StickyTarget.style.top = this.shh - this.section2StickyTarget.clientHeight / 2 + "px";
    }
}
