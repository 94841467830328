import TemplateContents from './TemplateContents';

export default class extends TemplateContents{
    constructor(param){
        super(param);
    }

    //ランディング時
    init() {
        // super.init();
        // this.pack.common.addScrollTarget(this);
        // this.pack.common.addEnterframeTarget(this);

        this.pack = window.TIER4;
        this.sw, this.sh;
        this.bindResizeHandler = this.resizeHandler.bind(this);
        this.hasTouch = ("ontouchstart" in window);
        this.resizeEvent = navigator.userAgent.match(/(iPhone|iPod|iPad)/) ? 'orientationchange' : 'resize';
    }

    setTarget(target){
        this.target = target;

        this.setVars();
        this.setDom();
        this.initEvents();
    }

    setOnlyPC(){
        this.onlyPC = true;
    }

    setOnlySP(){
        this.onlySP = true;
    }

    setNoSwipe(){
      this.noSwipe = true;
    }

    setMarginRightPC(isVW, value){
        this.hasMarginVWRightPC = isVW;
        if(isVW) this.marginVWRightPC = value;
        else this.marginRightPC = value;
        this.resizeHandler();
    }

    setMarginRightSP(isVW, value){
        this.hasMarginVWRightSP = isVW;
        if(isVW) this.marginVWRightSP = value;
        else this.marginRightSP= value;
        this.resizeHandler();
    }

    setAdjustMinXPC(isVW, value){
        this.hasMinXAdjustVWPC = isVW;
        if(isVW) this.minXAdjustVWPC = value;
        else this.minXAdjustPC = value;
        this.resizeHandler();
    }

    setAdjustMinXSP(isVW, value){
        this.hasMinXAdjustVWSP = isVW;
        if(isVW) this.minXAdjustVWSP = value;
        else this.minXAdjustSP = value;
        this.resizeHandler();
    }

    //他ページから画面遷移で来た時
    reset(){
        // super.reset();
        // this.setVars();
        // this.pack.common.addScrollTarget(this);
        // this.pack.common.addEnterframeTarget(this);
    }

    //他ページに移動する時
    destruct(){
        super.destruct();
        // this.pack.common.removeScrollTarget(this);
        // this.pack.common.removeEnterframeTarget(this);

        this.carousel.removeEventListener("mouseover", this.bindMouseOverHandler);
        this.carousel.removeEventListener("mouseout", this.bindMouseOutHandler);
        this.carouselWrap.removeEventListener("mousemove", this.bindMouseMoveHandler);
        this.carousel.removeEventListener(this.swipeStartEvent, this.bindStartSwipeHandler);
        this.carousel.removeEventListener(this.swipeStopEvent, this.bindStopSwipeHandler);

        for( let i = 0, len = this.anchors.length; i < len; i++ ){
            let item = this.anchors[i];
            item.removeEventListener("click", this.bindClickAnchorHandler);
        }

        if(this.next) this.next.removeEventListener("click", this.bindClickNextHandler);
        if(this.prev) this.prev.removeEventListener("click", this.bindClickPrevHandler);
    }

    //変数設定
    setVars(){
        super.setVars();
        this.swipeIntervalCnt = 0;
        this.swiped = false;
        this.swipeInterval;
        this.swipeStartAbsoluteX;
        this.swipeStartPoint = {x:0, y:0};
        this.pastSwipeX = 0;
        this.swipeX = 0;
        this.targetSwipeX = 0;
        this.enabled = false;
        this.marginRightPC = 0;
        this.marginRightSP = 0;
        this.hasMarginVWRightPC = false;
        this.hasMarginVWRightSP = false;
        this.marginVWRightPC = 0;
        this.marginVWRightSP = 0;

        this.minXAdjustPC = 0;
        this.minXAdjustSP = 0;
        this.hasMinXAdjustVWPC = false;
        this.hasMinXAdjustVWSP = false;
        this.minXAdjustVWPC = 0;
        this.minXAdjustVWSP = 0;
    }

    //dom設定
    setDom(){
        super.setDom();
        this.carouselWrap = this.target.querySelector('.carousel_wrap');
        this.carousel = this.target.querySelector('.carousel');
        this.carouselChilds = this.target.querySelectorAll('.carousel .carousel_item');
        this.anchors = this.target.querySelectorAll('.carousel .carousel_item a');
        this.prev = this.target.querySelector('.carousel_ui .prev_bt');
        this.next = this.target.querySelector('.carousel_ui .next_bt');
    }

    //イベント
    initEvents(){
        super.initEvents();

        this.swipingEvent = (this.pack.hasTouch) ? "touchmove" : "mousemove";
        this.swipeStartEvent = (this.pack.hasTouch) ? "touchstart" : "mousedown";
        this.swipeStopEvent = (this.pack.hasTouch) ? "touchend" : "mouseup";

        this.bindMouseOverHandler = this.mouseOverHandler.bind(this);
        this.bindMouseOutHandler = this.mouseOutHandler.bind(this);
        this.bindMouseMoveHandler = this.mouseMoveHandler.bind(this);
        this.bindStartSwipeHandler = this.startSwipeHandler.bind(this);
        this.bindSwipingHandler = this.swipingHandler.bind(this);
        this.bindStopSwipeHandler = this.stopSwipeHandler.bind(this);
        this.bindClickAnchorHandler = this.clickAnchorHandler.bind(this);
        this.bindClickNextHandler = this.clickNextHandler.bind(this);
        this.bindClickPrevHandler = this.clickPrevHandler.bind(this);

        this.carousel.addEventListener("mouseover", this.bindMouseOverHandler);
        this.carousel.addEventListener("mouseout", this.bindMouseOutHandler);
        this.carouselWrap.addEventListener("mousemove", this.bindMouseMoveHandler);
        this.carousel.addEventListener(this.swipeStartEvent, this.bindStartSwipeHandler);
        this.carousel.addEventListener(this.swipeStopEvent, this.bindStopSwipeHandler);

        for( let i = 0, len = this.anchors.length; i < len; i++ ){
            let item = this.anchors[i];
            item.addEventListener("click", this.bindClickAnchorHandler);
        }

        if(this.next) this.next.addEventListener("click", this.bindClickNextHandler);
        if(this.prev) this.prev.addEventListener("click", this.bindClickPrevHandler);
    }

    mouseMoveHandler(event){
        if(!this.enabled) return;
    }

    mouseOverHandler(event){
        if(!this.enabled) return;
    }

    mouseOutHandler(event){
        if(!this.enabled) return;
    }

    clickNextHandler(event){
        if(!this.enabled) return;
        let dr = 1.1;
        let singleW = this.carouselChilds[0].clientWidth;
        if(this.sw >= this.pack.BP) singleW += this.marginRightPC;
        else singleW += this.marginRightSP;

        let minX;
        if(this.sw >= this.pack.BP) minX = -singleW * this.carouselChilds.length + this.sw - this.minXAdjustPC;
        else minX = -singleW * this.carouselChilds.length + this.sw - this.minXAdjustSP;

        this.pastSwipeX = this.targetSwipeX -= singleW;
        if(this.targetSwipeX <= minX) this.pastSwipeX = this.targetSwipeX = minX;
        TweenMax.killTweensOf(this);
        TweenMax.to(this, dr, {swipeX:this.targetSwipeX, onUpdate:()=>{
            this.carousel.style.transform = 'translate3d(' + this.swipeX + 'px, 0, 0)';
        }, ease:Quart.easeOut});
    }

    clickPrevHandler(event){
        if(!this.enabled) return;
        let dr = 1.1;
        let singleW = this.carouselChilds[0].clientWidth;
        if(this.sw >= this.pack.BP) singleW += this.marginRightPC;
        else singleW += this.marginRightSP;

        this.pastSwipeX = this.targetSwipeX += singleW;
        if(this.targetSwipeX >= 0) this.pastSwipeX = this.targetSwipeX = 0;
        TweenMax.killTweensOf(this);
        TweenMax.to(this, dr, {swipeX:this.targetSwipeX, onUpdate:()=>{
            this.carousel.style.transform = 'translate3d(' + this.swipeX + 'px, 0, 0)';
        }, ease:Quart.easeOut});
    }

    clickAnchorHandler(event){
        if(!this.enabled) return;
        //swipe処理とのバッティングを避ける処理
        if(this.swipeIntervalCnt > 3 && this.swiped) {
            event.stopPropagation();
            event.preventDefault();
        }
    }

    startSwipeHandler(event){
        if(!this.enabled) return;
        if(this.noSwipe) return;
        let x;
        let hasTouch = event.type.indexOf('touch') == 0;

        this.swipeIntervalCnt = 0;
        this.swipeInterval = setInterval(()=>{
            this.swipeIntervalCnt++;
        }, 1000 / 30);

        if(hasTouch) {
            let touches = event.changedTouches[0];
            this.swipeStartAbsoluteX = touches.clientX;
            x = touches.clientX * 2;
        }else{
            event.preventDefault();
            this.swipeStartAbsoluteX = event.clientX;
            x = event.clientX * 2;
        }

        this.swipeStartPoint.x = x - this.pastSwipeX;

        document.addEventListener(this.swipeStopEvent, this.bindStopSwipeHandler);
        document.addEventListener(this.swipingEvent, this.bindSwipingHandler);
    }

    swipingHandler(event){
        if(!this.enabled) return;
        if(this.noSwipe) return;

        let x;
        let hasTouch = event.type.indexOf('touch') == 0;
        let dr = 1.1;

        let singleW = this.carouselChilds[0].clientWidth;
        if(this.sw >= this.pack.BP) singleW += this.marginRightPC;
        else singleW += this.marginRightSP;

        let minX;
        if(this.sw >= this.pack.BP) minX = -singleW * this.carouselChilds.length + this.sw - this.minXAdjustPC;
        else minX = -singleW * this.carouselChilds.length + this.sw - this.minXAdjustSP;

        if(hasTouch) {
            // event.preventDefault();
            let touches = event.changedTouches[0];
            x = touches.clientX * 2;
        }else{
            event.preventDefault();
            x = event.clientX * 2;
        }

        this.targetSwipeX = (x - this.swipeStartPoint.x);

        if(this.targetSwipeX <= minX) this.targetSwipeX = minX;
        else if(this.targetSwipeX >= 0) this.targetSwipeX = 0;

        TweenMax.killTweensOf(this);
        TweenMax.to(this, dr, {swipeX:this.targetSwipeX, onUpdate:()=>{
            this.carousel.style.transform = 'translate3d(' + this.swipeX + 'px, 0, 0)';
        }, ease:Quart.easeOut});
    }

    stopSwipeHandler(event){
        if(!this.enabled) return;
        if(this.noSwipe) return;

        let x;
        let hasTouch = event.type.indexOf('touch') == 0;

        if(hasTouch) {
            let touches = event.changedTouches[0];
            x = touches.clientX * 2;
        }else{
            x = event.clientX * 2;
        }

        //スワイプしたかどうか
        if(this.swipeStartAbsoluteX === x) this.swiped = false;
        else this.swiped = true;

        this.pastSwipeX = this.targetSwipeX;
        clearInterval(this.swipeInterval);
        document.removeEventListener(this.swipeStopEvent, this.bindStopSwipeHandler);
        document.removeEventListener(this.swipingEvent, this.bindSwipingHandler);
    }

    //60fps
    enterframe(){
        if(!this.enabled) return;
    }

    //30fps
    enterframeThinOut(){

    }

    //リサイズ
    executeResize() {
        super.executeResize();

        let carouselWidth = this.carousel.getBoundingClientRect().left + this.carousel.clientWidth;
        if(carouselWidth > this.sw) this.enabled = true;
        else this.enabled = false;

        if(this.sw >= this.pack.BP){
            if(this.onlySP) this.enabled = false;
            else{

            }
        }else if(this.sw < this.pack.BP){
            if(this.onlyPC) this.enabled = false;
            else{

            }
        }

        if(this.hasMarginVWRightPC) this.marginRightPC = this.sw * this.marginVWRightPC / 100;
        if(this.hasMarginVWRightSP) this.marginRightSP = this.sw * this.marginVWRightSP / 100;

        if(this.hasMinXAdjustVWPC) this.minXAdjustPC = this.sw * this.minXAdjustVWPC / 100;
        if(this.hasMinXAdjustVWSP) this.minXAdjustSP = this.sw * this.minXAdjustVWSP / 100;
    }
}
