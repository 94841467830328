import TemplateContents from './TemplateContents';

export default class extends TemplateContents{
    constructor(param){
        super(param);
    }

    init() {
        super.init();

        this.pack.common.addScrollTarget(this);
        // this.pack.common.addEnterframeTarget(this);
    }

    reset(){
        super.reset();

        this.setVars();
        this.pack.common.addScrollTarget(this);
        // this.pack.common.addEnterframeTarget(this);
    }

    destruct(){
        super.destruct();

        document.body.classList.remove("no_hidden");

        this.section1ImgContainer.removeEventListener(this.swipeStartEvent, this.bindStartSwipeHandler);
        this.section1ImgContainer.removeEventListener(this.swipeStopEvent, this.bindStopSwipeHandler);

        this.pack.common.removeScrollTarget(this);
        // this.pack.common.removeEnterframeTarget(this);
    }

    setVars(){
        super.setVars();
        this.swipeIntervalCnt = 0;
        this.swiped = false;
        this.swipeInterval;
        this.swipeStartAbsoluteX;
        this.swipeStartPoint = {x:0, y:0};
        this.pastSwipeX = 0;
        this.swipeX = 0;
        this.targetSwipeX = 0;
        this.enabled = false;
    }

    setDom(){
        super.setDom();

        this.next = document.querySelector('.next');

        this.section1ImgContainer = document.querySelector('main.products .section_chunk .section1 .section_child > .img');
        this.section1ImgSP = document.querySelector('main.products .section_chunk .section1 .section_child > .img img.sp');
        this.section1BarGrab = document.querySelector('main.products .section_chunk .section1 .section_child > .img .bar .grab');

        this.section2Chunks = document.querySelectorAll('main.products .section_chunk .section2 .section_child > section .right .chunk');
        this.section2Imgs = document.querySelectorAll('main.products .section_chunk .section2 .section_child > section .left img');
        this.section2StickyTarget = document.querySelector('main.products .section_chunk .section2 .section_child > section .left');

        this.section3Chunks = document.querySelectorAll('main.products .section_chunk .section3 .section_child > section .right .chunk');
        this.section3Imgs = document.querySelectorAll('main.products .section_chunk .section3 .section_child > section .left img');
        this.section3StickyTarget = document.querySelector('main.products .section_chunk .section3 .section_child > section .left');
    }

    initEvents(){
        super.initEvents();

        this.swipingEvent = (this.pack.hasTouch) ? "touchmove" : "mousemove";
        this.swipeStartEvent = (this.pack.hasTouch) ? "touchstart" : "mousedown";
        this.swipeStopEvent = (this.pack.hasTouch) ? "touchend" : "mouseup";
        this.bindStartSwipeHandler = this.startSwipeHandler.bind(this);
        this.bindSwipingHandler = this.swipingHandler.bind(this);
        this.bindStopSwipeHandler = this.stopSwipeHandler.bind(this);

        this.section1ImgContainer.addEventListener(this.swipeStartEvent, this.bindStartSwipeHandler);
        this.section1ImgContainer.addEventListener(this.swipeStopEvent, this.bindStopSwipeHandler);
        this.resizeHandler();
    }

    startSwipeHandler(event){
        if(!this.enabled) return;
        let x;
        let hasTouch = event.type.indexOf('touch') == 0;

        this.swipeIntervalCnt = 0;
        this.swipeInterval = setInterval(()=>{
            this.swipeIntervalCnt++;
        }, 1000 / 30);

        if(hasTouch) {
            let touches = event.changedTouches[0];
            this.swipeStartAbsoluteX = touches.clientX;
            x = touches.clientX * 2;
        }else{
            event.preventDefault();
            this.swipeStartAbsoluteX = event.clientX;
            x = event.clientX * 2;
        }

        this.swipeStartPoint.x = x - this.pastSwipeX;

        document.addEventListener(this.swipeStopEvent, this.bindStopSwipeHandler);
        document.addEventListener(this.swipingEvent, this.bindSwipingHandler);
    }

    swipingHandler(event){
        if(!this.enabled) return;
        let x;
        let hasTouch = event.type.indexOf('touch') == 0;
        let dr = 1.1;

        let targetW = this.section1ImgSP.clientWidth;
        let containerW = this.section1ImgContainer.clientWidth;
        let barW = this.section1BarGrab.clientWidth;
        let barMoveX = barW - (barW * this.section1BarRatio);

        let minX = -targetW + containerW;
        let targetToBarRatio = barMoveX / minX;

        if(hasTouch) {
            // event.preventDefault();
            let touches = event.changedTouches[0];
            x = touches.clientX * 2;
        }else{
            event.preventDefault();
            x = event.clientX * 2;
        }

        this.targetSwipeX = (x - this.swipeStartPoint.x);

        if(this.targetSwipeX <= minX) this.targetSwipeX = minX;
        else if(this.targetSwipeX >= 0) this.targetSwipeX = 0;

        TweenMax.killTweensOf(this);
        TweenMax.to(this, dr, {swipeX:this.targetSwipeX, onUpdate:()=>{
            this.section1ImgSP.style.transform = 'translate3d(' + this.swipeX + 'px, 0, 0)';
            this.section1BarGrab.style.transform = 'translate3d(' + this.swipeX * targetToBarRatio + 'px, 0, 0) scale(' + this.section1BarRatio + ')';
        }, ease:Quart.easeOut});
    }

    stopSwipeHandler(event){
        if(!this.enabled) return;
        let x;
        let hasTouch = event.type.indexOf('touch') == 0;

        if(hasTouch) {
            let touches = event.changedTouches[0];
            x = touches.clientX * 2;
        }else{
            x = event.clientX * 2;
        }

        //スワイプしたかどうか
        if(this.swipeStartAbsoluteX === x) this.swiped = false;
        else this.swiped = true;

        this.pastSwipeX = this.targetSwipeX;
        clearInterval(this.swipeInterval);
        document.removeEventListener(this.swipeStopEvent, this.bindStopSwipeHandler);
        document.removeEventListener(this.swipingEvent, this.bindSwipingHandler);
    }

    start(){
        super.start();
        document.body.classList.add("no_hidden");
    }

    scrollHandler(){
        this.pack.checkBG(this.next);
        this.pack.checkBottom() ? this.next.classList.add("show_fixed") : this.next.classList.remove("show_fixed");
        this.checkSection2();
        this.checkSection3();
    }

    checkSection2(){
        let current = 0;
        for( let i = 0, len = this.section2Chunks.length; i < len; i++ ){
            let item = this.section2Chunks[i];
            if(item.getBoundingClientRect().top < this.shh){
                current = i;
            }
        }

        for( let i = 0, len = this.section2Imgs.length; i < len; i++ ){
            let item = this.section2Imgs[i];
            let chunk = this.section2Chunks[i];
            item.classList.remove("show");
            chunk.classList.remove("clearify");
        }

        this.section2Imgs[current].classList.add("show");
        this.section2Chunks[current].classList.add("clearify");
    }

    checkSection3(){
        let current = 0;
        for( let i = 0, len = this.section3Chunks.length; i < len; i++ ){
            let item = this.section3Chunks[i];
            if(item.getBoundingClientRect().top < this.shh){
                current = i;
            }
        }

        for( let i = 0, len = this.section3Imgs.length; i < len; i++ ){
            let item = this.section3Imgs[i];
            let chunk = this.section3Chunks[i];
            item.classList.remove("show");
            chunk.classList.remove("clearify");
        }

        this.section3Imgs[current].classList.add("show");
        this.section3Chunks[current].classList.add("clearify");
    }

    enterframe(){

    }

    enterframeThinOut(){

    }

    executeResize() {
        super.executeResize();

        this.section1BarRatio = this.section1ImgContainer.clientWidth / this.section1ImgSP.clientWidth;
        this.section1BarGrab.style.transform = 'scale(' + this.section1BarRatio + ')';
        this.section1ImgSP.style.transform = 'translate3d(0, 0, 0)';
        this.swipeX = this.pastSwipeX = this.targetSwipeX = 0;

        this.section2StickyTarget.style.top = this.shh - this.section2StickyTarget.clientHeight / 2 + "px";
        this.section3StickyTarget.style.top = this.shh - this.section3StickyTarget.clientHeight / 2 + "px";

        if(this.sw >= this.pack.BP){
            this.enabled = false;
        }else{
            this.enabled = true;
        }
    }
}