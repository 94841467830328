import TemplateContents from './TemplateContents';

export default class extends TemplateContents{
    constructor(param){
        super(param);
    }

    init() {
        super.init();

        // this.pack.common.addScrollTarget(this);
        // this.pack.common.addEnterframeTarget(this);
    }

    reset(){
        super.reset();

        this.setVars();
        // this.pack.common.addScrollTarget(this);
        // this.pack.common.addEnterframeTarget(this);
    }

    destruct(){
        super.destruct();

        // this.pack.common.removeScrollTarget(this);
        // this.pack.common.removeEnterframeTarget(this);
    }

    setVars(){
        super.setVars();
    }

    setDom(){
        super.setDom();

        this.loadingDOM = document.querySelector('#loading');
        this.backgroundDOM = document.querySelector('#background');
        this.wrapDOM = document.querySelector('#wrap');
    }

    initEvents(){
        super.initEvents();
    }

    start(){
        this.pack.transition.transitIn(this.completeTransitIn, this);
        TweenMax.to(this.loadingDOM, 1, {delay:1, opacity:1, ease:Quart.easeOut});
    }

    completeTransitIn(){
        // this.backgroundDOM.style.clipPath = "none";
        this.backgroundDOM.classList.add("show");
        this.wrapDOM.classList.add("show");

        TweenMax.to(this.loadingDOM, 1, {opacity:0, ease:Quart.easeOut});
        this.pack.transition.transitOut();

/*        TweenMax.delayedCall(1.1, ()=>{
            this.pack.transition.changeToTransitColor();
        });*/
    }

    scrollHandler(){

    }


    enterframe(){

    }

    enterframeThinOut(){

    }

    executeResize() {
        super.executeResize();
    }
}