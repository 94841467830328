import TemplateContents from './TemplateContents';

export default class extends TemplateContents{
    constructor(param){
        super(param);
    }

    init() {
        super.init();

        this.pack.common.addScrollTarget(this);
        // this.pack.common.addEnterframeTarget(this);
    }

    reset(){
        super.reset();

        this.setVars();
        this.pack.common.addScrollTarget(this);
        // this.pack.common.addEnterframeTarget(this);
    }

    destruct(){
        super.destruct();

        this.section1ImgContainer.removeEventListener(this.swipeStartEvent, this.bindStartSwipeHandler);
        this.section1ImgContainer.removeEventListener(this.swipeStopEvent, this.bindStopSwipeHandler);


        for( let i = 0, len = this.videos.length; i < len; i++ ){
            let item = this.videos[i];
            item.removeEventListener("click", this.clickVideoHandler);
        }

        this.pack.common.removeScrollTarget(this);
        // this.pack.common.removeEnterframeTarget(this);
    }

    setVars(){
        super.setVars();
        this.swipeIntervalCnt = 0;
        this.swiped = false;
        this.swipeInterval;
        this.swipeStartAbsoluteX;
        this.swipeStartPoint = {x:0, y:0};
        this.pastSwipeX = 0;
        this.swipeX = 0;
        this.targetSwipeX = 0;
        this.enabled = false;
    }

    setDom(){
        super.setDom();

        this.next = document.querySelector('.next');

        this.section1ImgContainer = document.querySelector('main.alliance > .section1 .section_child .content1 .thumb');
        this.section1ImgSP = document.querySelector('main.alliance > .section1 .section_child .content1 .thumb img.sp');
        this.section1BarGrab = document.querySelector('main.alliance > .section1 .section_child .content1 .thumb .bar .grab');

        this.videos = document.querySelectorAll('main.alliance video');
    }

    initEvents(){
        super.initEvents();


        this.swipingEvent = (this.pack.hasTouch) ? "touchmove" : "mousemove";
        this.swipeStartEvent = (this.pack.hasTouch) ? "touchstart" : "mousedown";
        this.swipeStopEvent = (this.pack.hasTouch) ? "touchend" : "mouseup";
        this.bindStartSwipeHandler = this.startSwipeHandler.bind(this);
        this.bindSwipingHandler = this.swipingHandler.bind(this);
        this.bindStopSwipeHandler = this.stopSwipeHandler.bind(this);

        this.section1ImgContainer.addEventListener(this.swipeStartEvent, this.bindStartSwipeHandler);
        this.section1ImgContainer.addEventListener(this.swipeStopEvent, this.bindStopSwipeHandler);

        for( let i = 0, len = this.videos.length; i < len; i++ ){
            let item = this.videos[i];
            item.addEventListener("click", {id:i, sc:this, handleEvent:this.clickVideoHandler});
        }
    }

    clickVideoHandler(event){
        trace(event, this);
        let sc = this.sc;
        let id = this.id;

        let video = event.currentTarget;
        if(video.paused) {
            video.play();
            video.parentNode.classList.add("hide_icon");
        }else {
            video.pause();
            video.parentNode.classList.remove("hide_icon");
        }
    }

    startSwipeHandler(event){
        if(!this.enabled) return;
        let x;
        let hasTouch = event.type.indexOf('touch') == 0;

        this.swipeIntervalCnt = 0;
        this.swipeInterval = setInterval(()=>{
            this.swipeIntervalCnt++;
        }, 1000 / 30);

        if(hasTouch) {
            let touches = event.changedTouches[0];
            this.swipeStartAbsoluteX = touches.clientX;
            x = touches.clientX * 2;
        }else{
            event.preventDefault();
            this.swipeStartAbsoluteX = event.clientX;
            x = event.clientX * 2;
        }

        this.swipeStartPoint.x = x - this.pastSwipeX;

        document.addEventListener(this.swipeStopEvent, this.bindStopSwipeHandler);
        document.addEventListener(this.swipingEvent, this.bindSwipingHandler);
    }

    swipingHandler(event){
        if(!this.enabled) return;
        let x;
        let hasTouch = event.type.indexOf('touch') == 0;
        let dr = 1.1;

        let marginRight = this.sw * .112;   //左右余白分（5.6vw x 2）
        let targetW = this.section1ImgSP.clientWidth + marginRight;
        let containerW = this.section1ImgContainer.clientWidth;
        let barW = this.section1BarGrab.clientWidth;
        let barMoveX = barW - (barW * this.section1BarRatio);

        let minX = -targetW + containerW;
        let targetToBarRatio = barMoveX / minX;

        if(hasTouch) {
            // event.preventDefault();
            let touches = event.changedTouches[0];
            x = touches.clientX * 2;
        }else{
            event.preventDefault();
            x = event.clientX * 2;
        }

        this.targetSwipeX = (x - this.swipeStartPoint.x);

        if(this.targetSwipeX <= minX) this.targetSwipeX = minX;
        else if(this.targetSwipeX >= 0) this.targetSwipeX = 0;

        TweenMax.killTweensOf(this);
        TweenMax.to(this, dr, {swipeX:this.targetSwipeX, onUpdate:()=>{
                this.section1ImgSP.style.transform = 'translate3d(' + this.swipeX + 'px, 0, 0)';
                this.section1BarGrab.style.transform = 'translate3d(' + this.swipeX * targetToBarRatio + 'px, 0, 0) scale(' + this.section1BarRatio + ')';
            }, ease:Quart.easeOut});
    }

    stopSwipeHandler(event){
        if(!this.enabled) return;
        let x;
        let hasTouch = event.type.indexOf('touch') == 0;

        if(hasTouch) {
            let touches = event.changedTouches[0];
            x = touches.clientX * 2;
        }else{
            x = event.clientX * 2;
        }

        //スワイプしたかどうか
        if(this.swipeStartAbsoluteX === x) this.swiped = false;
        else this.swiped = true;

        this.pastSwipeX = this.targetSwipeX;
        clearInterval(this.swipeInterval);
        document.removeEventListener(this.swipeStopEvent, this.bindStopSwipeHandler);
        document.removeEventListener(this.swipingEvent, this.bindSwipingHandler);
    }

    start(){
        super.start();
    }

    scrollHandler(){
        this.pack.checkBG(this.next);
        this.pack.checkBottom() ? this.next.classList.add("show_fixed") : this.next.classList.remove("show_fixed");
    }


    enterframe(){

    }

    enterframeThinOut(){

    }

    executeResize() {
        super.executeResize();

        this.section1BarRatio = this.section1ImgContainer.clientWidth / this.section1ImgSP.clientWidth;
        this.section1BarGrab.style.transform = 'scale(' + this.section1BarRatio + ')';
        this.section1ImgSP.style.transform = 'translate3d(0, 0, 0)';
        this.swipeX = this.pastSwipeX = this.targetSwipeX = 0;

        if(this.sw >= this.pack.BP){
            this.enabled = false;
        }else{
            this.enabled = true;
        }
    }
}