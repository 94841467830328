import TemplateContents from './TemplateContents';

export default class extends TemplateContents{
    constructor(param){
        super(param);
    }

    init() {
        super.init();

        this.pack.common.addScrollTarget(this);
        // this.pack.common.addEnterframeTarget(this);
    }

    reset(){
        super.reset();

        this.setVars();
        this.pack.common.addScrollTarget(this);
        // this.pack.common.addEnterframeTarget(this);
    }

    destruct(){
        super.destruct();

        this.filterOpenBtn.removeEventListener("click", this.bindClickFilterOpenBtnHanler);
        this.filterCloseBtn.removeEventListener("click", this.bindClickFilterCloseBtnHanler);

        this.selectDivision.removeEventListener("change", this.bindChangeDivisionHandler);
        this.selectLocation.removeEventListener("change", this.bindChangeLOcationHandler);

        this.noMatchesResetBtn.removeEventListener("click", this.bindClickResetFilterBtnHandler);

        this.pack.common.removeScrollTarget(this);
        // this.pack.common.removeEnterframeTarget(this);
    }

    setVars(){
        super.setVars();

        this.jsonBaseList = [];
        this.locationList = [];
        this.displayList = [];

        this.currentDivision = undefined;
        this.currentLocation = undefined;
    }

    setDom(){
        super.setDom();

        this.next = document.querySelector('.next');

        this.filter = document.querySelector('main.career > .section3 .filter');
        this.filterOpenBtn = document.querySelector('main.career > .section3 .filter .filter_btn');
        this.filterCloseBtn = document.querySelector('main.career > .section3 .filter .content .close');

        this.section2 = document.querySelector('main.career .section2');
        this.categoriesDOM = document.querySelector('main.career .section2 .section_child .categories');
        this.noMatchesDOM = document.querySelector('main.career .section2 .section_child .no_matches');
        this.noMatchesResetBtn = document.querySelector('main.career .section2 .section_child .no_matches .txt span');
        this.selectDivision = document.querySelector('main.career > .section3 .filter .content .all .division select');
        this.selectLocation = document.querySelector('main.career > .section3 .filter .content .all .location select');

        this.searchInputText = document.querySelector('main.career > .section3 .filter .content .search #career_search_input');
        this.searchInputBtn = document.querySelector('main.career > .section3 .filter .content .search button');
    }

    initEvents(){
        super.initEvents();

        this.bindClickFilterOpenBtnHanler = this.clickFilterOpenBtnHanler.bind(this);
        this.bindClickFilterCloseBtnHanler = this.clickFilterCloseBtnHanler.bind(this);
        this.bindChangeDivisionHandler = this.changeDivisionHandler.bind(this);
        this.bindChangeLOcationHandler = this.changeLocationHandler.bind(this);
        this.bindClickResetFilterBtnHandler = this.clickResetFilterBtnHandler.bind(this);

        this.filterOpenBtn.addEventListener("click", this.bindClickFilterOpenBtnHanler);
        this.filterCloseBtn.addEventListener("click", this.bindClickFilterCloseBtnHanler);

        this.selectDivision.addEventListener("change", this.bindChangeDivisionHandler);
        this.selectLocation.addEventListener("change", this.bindChangeLOcationHandler);

        this.noMatchesResetBtn.addEventListener("click", this.bindClickResetFilterBtnHandler);


        this.searchInputText.addEventListener("input", (event)=>{
            this.filterOpenPositions();
        });

        this.searchInputBtn.addEventListener("click", (event)=>{
            this.filterOpenPositions();
        });

        this.searchInputText.addEventListener("keydown", (event)=>{
            if ((event.which && event.which === 13) || (event.keyCode && event.keyCode === 13)) {
                this.filterOpenPositions();
            }
        });
    }

    clickResetFilterBtnHandler(event){
        this.currentDivision = undefined;
        this.currentLocation = undefined;

        let divisionOptions = this.selectDivision.querySelectorAll("option");
        let locationOptions = this.selectLocation.querySelectorAll("option");

        for( let i = 0, len = divisionOptions.length; i < len; i++ ){
            let item = divisionOptions[i];
            item.selected = false;
        }

        for( let i = 0, len = locationOptions.length; i < len; i++ ){
            let item = locationOptions[i];
            item.selected = false;
        }

        this.searchInputText.value = "";

        this.filterOpenPositions();
    }

    changeDivisionHandler(event){
        let value = event.currentTarget.value;
        this.currentDivision = value;
        this.filterOpenPositions();
    }

    changeLocationHandler(event){
        let value = event.currentTarget.value;
        this.currentLocation = value;
        this.filterOpenPositions();
    }

    clickFilterOpenBtnHanler(event){
        this.filter.classList.add("filter_open");
    }

    clickFilterCloseBtnHanler(event){
        this.filter.classList.remove("filter_open");
    }

    start(){
        super.start();
        this.setValues();
        this.getEachCategory();
    }

    setValues(){
        // let items = this.pack.contentfulJSONList[4].items;
        let items = this.pack.contentfulJSONList['career'].items;
        this.jsonEachCategoryPathList = [];
        this.eachCategoryList = [];
        for( let i = 0, len = items.length; i < len; i++ ){
            let item = items[i];
            let fields = item.fields;
            let sys = item.sys;
            let id = sys.id;
            let name = this.pack.getName(fields);

            let option = document.createElement("option");
            option.text =　(name.length >= 17) ? name.substr(0, 17) + "..." : name;
            option.value = name;
            this.selectDivision.appendChild(option);

            this.eachCategoryList[i] = name;
            this.jsonEachCategoryPathList[i] = this.pack.getContentfulAPICareerPath(id);
        }

        // trace(this.jsonEachCategoryPathList);
        // trace(this.eachCategoryList);
    }

    getEachCategory(){
        let urlList = this.jsonEachCategoryPathList;
        let promiseList = [];

        urlList.forEach ((url, i) => {
            promiseList.push (
                fetch(url).then ((res) => {
                    return res.json();
                }).then( (res) => {
                    this.jsonBaseList[i] = res;
                })
            );
        });

        Promise
            .all(promiseList)
            .then (()=> {
                trace("career all catetories loaded", this.jsonBaseList);
                this.displayList = this.jsonBaseList;
                this.setOpenPositions();
                this.setLocations();
            });

    }

    filterOpenPositions(){
        this.noMatchesDOM.classList.remove("show");
        this.categoriesDOM.innerHTML = "";
        this.displayList = [];

        for( let i = 0, len = this.jsonBaseList.length; i < len; i++ ){
            let items = this.jsonBaseList[i].items;
            let len2 = items.length;
            let categoryName = this.eachCategoryList[i];
            if(this.currentDivision && this.currentDivision != "ALL DIVISION"){
                if(this.currentDivision != categoryName) continue;
            }

            let new_items = [];

            for( let j = 0; j < len2; j++ ){
                let item = items[j].fields;
                let location = this.pack.getLocation(item);
                if(this.currentLocation && this.currentLocation != "ALL LOCATION"){
                    if(this.currentLocation != location) continue;
                }
                new_items.push(items[j]);
            }

            this.displayList[i] = {items:new_items};
        }

        //リストの先頭に自動スクロール
        this.pack.anchorScroll(this.section2.getBoundingClientRect().top + window.pageYOffset - 100, .3);

        //表示
        this.setOpenPositions();
    }

    setOpenPositions(){
        let cnt = 0;
        let searchString = this.searchInputText.value;

        for( let i = 0, len = this.displayList.length; i < len; i++ ){
            if(!this.displayList[i]) continue;
            let items = this.displayList[i].items;
            let len2 = items.length;
            if(len2 === 0) continue;
            let categoryName = this.eachCategoryList[i];
            let src = "";
            let cnt2 = 0;
            for( let j = 0; j < len2; j++ ){

                let item = items[j].fields;
                let name = this.pack.getName(item);
                let location = this.pack.getLocation(item);

                if(searchString){
                    //文言検索
                    let isHit = false;
                    if(categoryName.indexOf(searchString) > -1) isHit = true;
                    if(name.indexOf(searchString) > -1) isHit = true;
                    if(location.indexOf(searchString) > -1) isHit = true;
                    if(!isHit) continue;
                }

                let job = this.getJob(item.link, name, location);
                src += job;
                cnt2++;
            }

            if(cnt2 > 0){
                //1件以上だったらcategory生成
                let category = this.getCategory(categoryName, len2);
                this.categoriesDOM.appendChild(category);
                let jobs = category.querySelector(".jobs");
                jobs.innerHTML = src;
                cnt++;
            }
        }

        //1件も引っかからない時
        if(cnt === 0) this.noMatchesDOM.classList.add("show");
    }

    setLocations(){
        for( let i = 0, len = this.jsonBaseList.length; i < len; i++ ){
            let items = this.jsonBaseList[i].items;
            let len2 = items.length;
            for( let j = 0; j < len2; j++ ){
                let item = items[j].fields;
                let location = this.pack.getLocation(item);
                this.locationList.push(location);
            }
        }

        //locationの抽出
        this.locationList = this.locationList.filter(function (x, i, self) {
            return self.indexOf(x) === i;
        });

        for( let i = 0, len = this.locationList.length; i < len; i++ ){
            let item = this.locationList[i];
            let option = document.createElement("option");
            option.text =　(item.length >= 14) ? item.substr(0, 14) + "..." : item;
            option.value = item;
            this.selectLocation.appendChild(option);
        }
    }

    getCategory(name, len){
        /* pug src
        .category#software
            h3
                span.tit SOFTWARE
                span.number 4
            .jobs
         */
        let div = document.createElement("div");
        let font2 = (this.pack.LANG === "en") ? "" : "font2";

        div.classList.add("category");
        div.innerHTML = `
            <h3>
                <span class="tit ${font2}">${name}</span><span class="number">${len}</span>
            </h3>
            <div class="jobs"></div>
        `;

        return div;
    }


    getJob(link, name, location){
        /* pug src
        .job
            a(href="")
                p.name
                    | Software Engineer (system programings)
                p.location
                    | SHINAGAWA
         */
        let font2 = (this.pack.LANG === "en") ? "" : "font2";

        return `
            <div class="job"><a href="${link}" target="_blank">
              <p class="name ${font2}">${name}</p>
              <p class="location ${font2}">${location}</p></a></div>
        `;
    }

    scrollHandler(){
        this.pack.checkBG(this.next);
        this.pack.checkBottom() ? this.next.classList.add("show_fixed") : this.next.classList.remove("show_fixed");
        this.scrollSection2();
    }

    scrollSection2(){
        let section2Y = this.section2.getBoundingClientRect().top;
        if( section2Y <= this.sh){
            if(section2Y + this.section2.clientHeight < this.sh) this.filterOpenBtn.classList.remove("show");
            else this.filterOpenBtn.classList.add("show");
        }else{
            this.filterOpenBtn.classList.remove("show");
        }
    }

    enterframe(){

    }

    enterframeThinOut(){

    }

    executeResize() {
        super.executeResize();
    }
}
