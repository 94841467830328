import TemplateContents from './TemplateContents';

export default class extends TemplateContents{
    constructor(param){
        super(param);
    }

    init() {
        super.init();

        this.pack.common.addScrollTarget(this);
        // this.pack.common.addEnterframeTarget(this);
    }

    reset(){
        super.reset();

        this.setVars();
        this.pack.common.addScrollTarget(this);
        // this.pack.common.addEnterframeTarget(this);
    }

    destruct(){
        super.destruct();

        this.pack.common.removeScrollTarget(this);
        // this.pack.common.removeEnterframeTarget(this);
    }

    destructAfterContentsOut(){
        this.next.classList.remove("simple");
        this.footerDOM.classList.remove("simple");
    }


    setVars(){
        super.setVars();
    }

    setDom(){
        super.setDom();

        this.next = document.querySelector('.next');
        this.footerDOM = document.querySelector('footer');

    }

    initEvents(){
        super.initEvents();
    }

    start(){
        super.start();

        this.next.classList.add("simple");
        this.footerDOM.classList.add("simple");
    }

    scrollHandler(){
        this.pack.checkBG(this.next);
    }


    enterframe(){

    }

    enterframeThinOut(){

    }

    executeResize() {
        super.executeResize();
    }
}