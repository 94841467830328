import TemplateContents from './TemplateContents';
require('./libs/minMatrix.js');

export default class extends TemplateContents{
    constructor(param){
        super(param);
    }

    init() {
        super.init();

        this.initShader();
        this.initWebGL();
        this.initMetaball();
        this.update();
        this.resizeHandler();

        // this.pack.common.addScrollTarget(this);
        // this.pack.common.addEnterframeTarget(this);
    }

    reset(){
        super.reset();

        this.setVars();
        // this.pack.common.addScrollTarget(this);
        // this.pack.common.addEnterframeTarget(this);
    }

    destruct(){
        super.destruct();

        // this.pack.common.removeScrollTarget(this);
        // this.pack.common.removeEnterframeTarget(this);
    }

    setVars(){
        super.setVars();
        this.numMetaballs = 7;
        this.metaBalls = [];
        this.defMetaBallsRadius = [];
        this.NORMAL_R = this.NORMAL_G = this.NORMAL_B = 251/255;
        this.FOOTER_R = 172./255.;
        this.FOOTER_G = 197./255.;
        this.FOOTER_B = 215./255.;
        this.currentR = this.NORMAL_R;
        this.currentG = this.NORMAL_G;
        this.currentB = this.NORMAL_B;

        this.METABALL_COLOR_LEN = 4;
        this.METABALL_COLOR_1R = 5./255.;
        this.METABALL_COLOR_1G = 32./255.;
        this.METABALL_COLOR_1B = 81./255.;

        this.METABALL_COLOR_2R = 40./255.;
        this.METABALL_COLOR_2G = 55./255.;
        this.METABALL_COLOR_2B = 81./255.;

        this.METABALL_COLOR_3R = 161./255.;
        this.METABALL_COLOR_3G = 191./255.;
        this.METABALL_COLOR_3B = 211./255.;

        this.METABALL_COLOR_4R = 140./255.;
        this.METABALL_COLOR_4G = 174./255.;
        this.METABALL_COLOR_4B = 198./255.;

/*
        this.METABALL_COLOR_1R = 50./255.;
        this.METABALL_COLOR_1G = 64./255.;
        this.METABALL_COLOR_1B = 96./255.;

        this.METABALL_COLOR_2R = 149./255.;
        this.METABALL_COLOR_2G = 180./255.;
        this.METABALL_COLOR_2B = 204./255.;

        this.METABALL_COLOR_3R = 97./255.;
        this.METABALL_COLOR_3G = 106./255.;
        this.METABALL_COLOR_3B = 127./255.;

        this.METABALL_COLOR_4R = 247./255.;
        this.METABALL_COLOR_4G = 251./255.;
        this.METABALL_COLOR_4B = 251./255.;
*/

        this.currentMetaballColors = [
            {r:this.METABALL_COLOR_1R, g:this.METABALL_COLOR_1G, b:this.METABALL_COLOR_1B},
            {r:this.METABALL_COLOR_2R, g:this.METABALL_COLOR_2G, b:this.METABALL_COLOR_2B},
            {r:this.METABALL_COLOR_3R, g:this.METABALL_COLOR_3G, b:this.METABALL_COLOR_3B},
            {r:this.METABALL_COLOR_4R, g:this.METABALL_COLOR_4G, b:this.METABALL_COLOR_4B},
        ];
        this.currentMetaballColorPatern = 1;


        this.bgPatern = 1;
    }

    setDom(){
        super.setDom();

        this.canvasContainer = document.querySelector('#background');
        this.canvas = document.querySelector('#background canvas');

    }

    initEvents(){
        super.initEvents();

    }

    initShader() {
        this.vertexShaderSrc = `
            attribute vec2 position;
    
            void main() {
                // position specifies only x and y.
                // We set z to be 0.0, and w to be 1.0
                gl_Position = vec4(position, 0.0, 1.0);
            }
        `;

        this.fragmentShaderSrc = `
            precision highp float;
            
            const float WIDTH = ` + (this.sw >> 0) + `.0;
            const float HEIGHT = ` + (this.sh >> 0) + `.0;
            
            uniform vec3 metaballs[` + this.numMetaballs + `];
            uniform vec2 resolution;
            uniform vec3 bgColor;
            uniform vec3 metaballColors[`+ this.METABALL_COLOR_LEN + `];
            
            void main(){
                float x = gl_FragCoord.x;
                float y = gl_FragCoord.y;
                
                float sum = 0.0;
                for (int i = 0; i < ` + this.numMetaballs + `; i++) {
                    vec3 metaball = metaballs[i];
                    float dx = metaball.x - x;
                    float dy = metaball.y - y;
                    float radius = metaball.z;                    
                    sum += (radius * radius) / (dx * dx + dy * dy);
                }
                
                if (sum >= 0.99) {
                    vec2 st = gl_FragCoord.xy / resolution.xy;

                    vec3 tc = mix(metaballColors[0], metaballColors[1], st.x);
                    vec3 bc =  mix(metaballColors[2], metaballColors[3], st.x);
                    vec3 color = mix(tc, bc, st.y);
                         
                    gl_FragColor = vec4(color,1.0);
                    
                    // gl_FragColor = vec4(mix(vec3(x / WIDTH, y / HEIGHT, 1.0), vec3(R, G, B), max(0.0, 1.0 - (sum - 0.99) * 100.0)), 1.0);
                    return;
                }
                
                gl_FragColor = vec4(bgColor, 1.0);
            }
        `;

        this.gVertexShaderSrc = `
            attribute vec3 position;
            attribute vec2 texCoord;
            uniform   mat4 mvpMatrix;
            varying   vec2 vTexCoord;
    
            void main(void){
                vTexCoord   = texCoord;
                gl_Position = mvpMatrix * vec4(position, 1.0);
            }
        `;

        this.gFragmentShaderSrc = `
            precision mediump float;

            uniform sampler2D texture;
            uniform bool      grayScale;
            varying vec2      vTexCoord;
            uniform float     test;
    
            const float redScale   = 0.298912;
            const float greenScale = 0.586611;
            const float blueScale  = 0.114478;
            const vec3  monochromeScale = vec3(redScale, greenScale, blueScale);
    
            void main(void){
                vec4 smpColor = texture2D(texture, vTexCoord);
                if(grayScale){
                    float grayColor = dot(smpColor.rgb, monochromeScale);
                    smpColor = vec4(vec3(grayColor), 1.0);
                }
                gl_FragColor = smpColor;
            }
        `;


/*
        this.bVertexShaderSrc = `
            attribute vec3 position;
            attribute vec4 color;
            uniform   mat4 mvpMatrix;
            varying   vec4 vColor;
            
            void main(void){
                vColor = color;
                gl_Position = mvpMatrix * vec4(position, 1.0);
            }
        `;

        this.bFragmentShaderSrc = `
            precision mediump float;

            uniform sampler2D texture;
            uniform bool      useBlur;
            varying vec4      vColor;
            
            void main(void){
                vec2 tFrag = vec2(1.0 / ` + this.sw + `.);
                vec4 destColor = texture2D(texture, gl_FragCoord.st * tFrag);
                if(useBlur){
                    destColor *= 0.36;
                    destColor += texture2D(texture, (gl_FragCoord.st + vec2(-1.0,  1.0)) * tFrag) * 0.04;
                    destColor += texture2D(texture, (gl_FragCoord.st + vec2( 0.0,  1.0)) * tFrag) * 0.04;
                    destColor += texture2D(texture, (gl_FragCoord.st + vec2( 1.0,  1.0)) * tFrag) * 0.04;
                    destColor += texture2D(texture, (gl_FragCoord.st + vec2(-1.0,  0.0)) * tFrag) * 0.04;
                    destColor += texture2D(texture, (gl_FragCoord.st + vec2( 1.0,  0.0)) * tFrag) * 0.04;
                    destColor += texture2D(texture, (gl_FragCoord.st + vec2(-1.0, -1.0)) * tFrag) * 0.04;
                    destColor += texture2D(texture, (gl_FragCoord.st + vec2( 0.0, -1.0)) * tFrag) * 0.04;
                    destColor += texture2D(texture, (gl_FragCoord.st + vec2( 1.0, -1.0)) * tFrag) * 0.04;
                    destColor += texture2D(texture, (gl_FragCoord.st + vec2(-2.0,  2.0)) * tFrag) * 0.02;
                    destColor += texture2D(texture, (gl_FragCoord.st + vec2(-1.0,  2.0)) * tFrag) * 0.02;
                    destColor += texture2D(texture, (gl_FragCoord.st + vec2( 0.0,  2.0)) * tFrag) * 0.02;
                    destColor += texture2D(texture, (gl_FragCoord.st + vec2( 1.0,  2.0)) * tFrag) * 0.02;
                    destColor += texture2D(texture, (gl_FragCoord.st + vec2( 2.0,  2.0)) * tFrag) * 0.02;
                    destColor += texture2D(texture, (gl_FragCoord.st + vec2(-2.0,  1.0)) * tFrag) * 0.02;
                    destColor += texture2D(texture, (gl_FragCoord.st + vec2( 2.0,  1.0)) * tFrag) * 0.02;
                    destColor += texture2D(texture, (gl_FragCoord.st + vec2(-2.0,  0.0)) * tFrag) * 0.02;
                    destColor += texture2D(texture, (gl_FragCoord.st + vec2( 2.0,  0.0)) * tFrag) * 0.02;
                    destColor += texture2D(texture, (gl_FragCoord.st + vec2(-2.0, -1.0)) * tFrag) * 0.02;
                    destColor += texture2D(texture, (gl_FragCoord.st + vec2( 2.0, -1.0)) * tFrag) * 0.02;
                    destColor += texture2D(texture, (gl_FragCoord.st + vec2(-2.0, -2.0)) * tFrag) * 0.02;
                    destColor += texture2D(texture, (gl_FragCoord.st + vec2(-1.0, -2.0)) * tFrag) * 0.02;
                    destColor += texture2D(texture, (gl_FragCoord.st + vec2( 0.0, -2.0)) * tFrag) * 0.02;
                    destColor += texture2D(texture, (gl_FragCoord.st + vec2( 1.0, -2.0)) * tFrag) * 0.02;
                    destColor += texture2D(texture, (gl_FragCoord.st + vec2( 2.0, -2.0)) * tFrag) * 0.02;
                }
                gl_FragColor  = vColor * destColor;
            }
        `;
*/

        this.bVertexShaderSrc = `
           attribute vec3 position;
            attribute vec2 texCoord;
            uniform   mat4 mvpMatrix;
            varying   vec2 vTexCoord;
            
            void main(void){
                vTexCoord   = texCoord;
                gl_Position = mvpMatrix * vec4(position, 1.0);
            }
        `;


        this.bFragmentShaderSrc = `
            precision mediump float;
            
            uniform sampler2D texture;
            uniform bool      gaussian;
            uniform float     weight[10];
            uniform bool      horizontal;
            varying vec2      vTexCoord;
            
            void main(void){
                // float tFrag = 1.0 / 512.0;
                float tFrag = 1.0 / ` + this.sw + `.;
                vec2  fc;
                vec3  destColor = vec3(0.0);
                
                if(gaussian){

                    if(horizontal){
                        // fc = vec2(gl_FragCoord.s, 512.0 - gl_FragCoord.t);
                        fc = vec2(` + this.sw + `. - gl_FragCoord.s, ` + this.sw + `. - gl_FragCoord.t);
                        destColor += texture2D(texture, (fc + vec2(-9.0, 0.0)) * tFrag).rgb * weight[9];
                        destColor += texture2D(texture, (fc + vec2(-8.0, 0.0)) * tFrag).rgb * weight[8];
                        destColor += texture2D(texture, (fc + vec2(-7.0, 0.0)) * tFrag).rgb * weight[7];
                        destColor += texture2D(texture, (fc + vec2(-6.0, 0.0)) * tFrag).rgb * weight[6];
                        destColor += texture2D(texture, (fc + vec2(-5.0, 0.0)) * tFrag).rgb * weight[5];
                        destColor += texture2D(texture, (fc + vec2(-4.0, 0.0)) * tFrag).rgb * weight[4];
                        destColor += texture2D(texture, (fc + vec2(-3.0, 0.0)) * tFrag).rgb * weight[3];
                        destColor += texture2D(texture, (fc + vec2(-2.0, 0.0)) * tFrag).rgb * weight[2];
                        destColor += texture2D(texture, (fc + vec2(-1.0, 0.0)) * tFrag).rgb * weight[1];
                        destColor += texture2D(texture, (fc + vec2( 0.0, 0.0)) * tFrag).rgb * weight[0];
                        destColor += texture2D(texture, (fc + vec2( 1.0, 0.0)) * tFrag).rgb * weight[1];
                        destColor += texture2D(texture, (fc + vec2( 2.0, 0.0)) * tFrag).rgb * weight[2];
                        destColor += texture2D(texture, (fc + vec2( 3.0, 0.0)) * tFrag).rgb * weight[3];
                        destColor += texture2D(texture, (fc + vec2( 4.0, 0.0)) * tFrag).rgb * weight[4];
                        destColor += texture2D(texture, (fc + vec2( 5.0, 0.0)) * tFrag).rgb * weight[5];
                        destColor += texture2D(texture, (fc + vec2( 6.0, 0.0)) * tFrag).rgb * weight[6];
                        destColor += texture2D(texture, (fc + vec2( 7.0, 0.0)) * tFrag).rgb * weight[7];
                        destColor += texture2D(texture, (fc + vec2( 8.0, 0.0)) * tFrag).rgb * weight[8];
                        destColor += texture2D(texture, (fc + vec2( 9.0, 0.0)) * tFrag).rgb * weight[9];
                    }else{
                        fc = gl_FragCoord.st;
                        destColor += texture2D(texture, (fc + vec2(0.0, -9.0)) * tFrag).rgb * weight[9];
                        destColor += texture2D(texture, (fc + vec2(0.0, -8.0)) * tFrag).rgb * weight[8];
                        destColor += texture2D(texture, (fc + vec2(0.0, -7.0)) * tFrag).rgb * weight[7];
                        destColor += texture2D(texture, (fc + vec2(0.0, -6.0)) * tFrag).rgb * weight[6];
                        destColor += texture2D(texture, (fc + vec2(0.0, -5.0)) * tFrag).rgb * weight[5];
                        destColor += texture2D(texture, (fc + vec2(0.0, -4.0)) * tFrag).rgb * weight[4];
                        destColor += texture2D(texture, (fc + vec2(0.0, -3.0)) * tFrag).rgb * weight[3];
                        destColor += texture2D(texture, (fc + vec2(0.0, -2.0)) * tFrag).rgb * weight[2];
                        destColor += texture2D(texture, (fc + vec2(0.0, -1.0)) * tFrag).rgb * weight[1];
                        destColor += texture2D(texture, (fc + vec2(0.0,  0.0)) * tFrag).rgb * weight[0];
                        destColor += texture2D(texture, (fc + vec2(0.0,  1.0)) * tFrag).rgb * weight[1];
                        destColor += texture2D(texture, (fc + vec2(0.0,  2.0)) * tFrag).rgb * weight[2];
                        destColor += texture2D(texture, (fc + vec2(0.0,  3.0)) * tFrag).rgb * weight[3];
                        destColor += texture2D(texture, (fc + vec2(0.0,  4.0)) * tFrag).rgb * weight[4];
                        destColor += texture2D(texture, (fc + vec2(0.0,  5.0)) * tFrag).rgb * weight[5];
                        destColor += texture2D(texture, (fc + vec2(0.0,  6.0)) * tFrag).rgb * weight[6];
                        destColor += texture2D(texture, (fc + vec2(0.0,  7.0)) * tFrag).rgb * weight[7];
                        destColor += texture2D(texture, (fc + vec2(0.0,  8.0)) * tFrag).rgb * weight[8];
                        destColor += texture2D(texture, (fc + vec2(0.0,  9.0)) * tFrag).rgb * weight[9];
		            }
                }else{
                    destColor = texture2D(texture, vTexCoord).rgb;
                }
                
                gl_FragColor = vec4(destColor, 1.0);
            }        
        `;
    }

    compileShader(shaderSource, shaderType) {
        let shader = this.gl.createShader(shaderType);
        this.gl.shaderSource(shader, shaderSource);
        this.gl.compileShader(shader);

        if (!this.gl.getShaderParameter(shader, this.gl.COMPILE_STATUS)) {
            throw "Shader compile failed with: " + this.gl.getShaderInfoLog(shader);
        }

        return shader;
    }

    getAttribLocation(program, name) {
        let attributeLocation = this.gl.getAttribLocation(program, name);
        if (attributeLocation === -1) {
            throw 'Can not find attribute ' + name + '.';
        }
        return attributeLocation;
    }

    getUniformLocation(program, name) {
        let uniformLocation = this.gl.getUniformLocation(program, name);
        if (uniformLocation === -1) {
            throw 'Can not find uniform ' + name + '.';
        }
        return uniformLocation;
    }

    setAttribute(gl, vbo, attL, attS){
        for(var i in vbo){
            gl.bindBuffer(gl.ARRAY_BUFFER, vbo[i]);
            gl.enableVertexAttribArray(attL[i]);
            gl.vertexAttribPointer(attL[i], attS[i], gl.FLOAT, false, 0, 0);
        }
    }

    createVBO(gl, data){
        // バッファオブジェクトの生成
        var vbo = gl.createBuffer();

        // バッファをバインドする
        gl.bindBuffer(gl.ARRAY_BUFFER, vbo);

        // バッファにデータをセット
        gl.bufferData(gl.ARRAY_BUFFER, new Float32Array(data), gl.STATIC_DRAW);

        // バッファのバインドを無効化
        gl.bindBuffer(gl.ARRAY_BUFFER, null);

        // 生成した VBO を返して終了
        return vbo;
    }

    createIBO(gl, data){

        // バッファオブジェクトの生成
        var ibo = gl.createBuffer();

        // バッファをバインドする
        gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, ibo);

        // バッファにデータをセット
        gl.bufferData(gl.ELEMENT_ARRAY_BUFFER, new Int16Array(data), gl.STATIC_DRAW);

        // バッファのバインドを無効化
        gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, null);

        // 生成したIBOを返して終了
        return ibo;
    }

    createFramebuffer(gl, width, height){
        // フレームバッファの生成
        var frameBuffer = gl.createFramebuffer();

        // フレームバッファをWebGLにバインド
        gl.bindFramebuffer(gl.FRAMEBUFFER, frameBuffer);

        // 深度バッファ用レンダーバッファの生成とバインド
        var depthRenderBuffer = gl.createRenderbuffer();
        gl.bindRenderbuffer(gl.RENDERBUFFER, depthRenderBuffer);

        // レンダーバッファを深度バッファとして設定
        gl.renderbufferStorage(gl.RENDERBUFFER, gl.DEPTH_COMPONENT16, width, height);

        // フレームバッファにレンダーバッファを関連付ける
        gl.framebufferRenderbuffer(gl.FRAMEBUFFER, gl.DEPTH_ATTACHMENT, gl.RENDERBUFFER, depthRenderBuffer);

        // フレームバッファ用テクスチャの生成
        var fTexture = gl.createTexture();

        // フレームバッファ用のテクスチャをバインド
        gl.bindTexture(gl.TEXTURE_2D, fTexture);

        // フレームバッファ用のテクスチャにカラー用のメモリ領域を確保
        gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA, width, height, 0, gl.RGBA, gl.UNSIGNED_BYTE, null);

        // テクスチャパラメータ
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.LINEAR);
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR);
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);

        // フレームバッファにテクスチャを関連付ける
        gl.framebufferTexture2D(gl.FRAMEBUFFER, gl.COLOR_ATTACHMENT0, gl.TEXTURE_2D, fTexture, 0);

        // 各種オブジェクトのバインドを解除
        gl.bindTexture(gl.TEXTURE_2D, null);
        gl.bindRenderbuffer(gl.RENDERBUFFER, null);
        gl.bindFramebuffer(gl.FRAMEBUFFER, null);

        // オブジェクトを返して終了
        return {f : frameBuffer, d : depthRenderBuffer, t : fTexture};
    }

    initWebGL() {
        this.gl = this.canvas.getContext('webgl');

        // this.initWebGLForGrayscale();
        this.initWebGLForBlur();

        // フレームバッファオブジェクトの取得
        this.fBuffer = this.createFramebuffer(this.gl, window.innerWidth, window.innerHeight);
        this.fBuffer2 = this.createFramebuffer(this.gl, window.innerWidth, window.innerHeight);

        // 各種行列の生成と初期化
        this.m = new matIV();
        this.mMatrix = this.m.identity(this.m.create());
        this.vMatrix = this.m.identity(this.m.create());
        this.pMatrix = this.m.identity(this.m.create());
        this.tmpMatrix = this.m.identity(this.m.create());
        this.mvpMatrix = this.m.identity(this.m.create());
        this.invMatrix = this.m.identity(this.m.create());

        this.initWebGLForMetaball();
    }

    initWebGLForMetaball(){
        let vertexShader = this.compileShader(this.vertexShaderSrc, this.gl.VERTEX_SHADER);
        let fragmentShader = this.compileShader(this.fragmentShaderSrc, this.gl.FRAGMENT_SHADER);
        let program = this.program = this.gl.createProgram();
        this.gl.attachShader(program, vertexShader);
        this.gl.attachShader(program, fragmentShader);
        this.gl.linkProgram(program);
        this.gl.useProgram(program);

        //attribute
        let vertexData = [
            -1.0,  1.0, // top left
            -1.0, -1.0, // bottom left
            1.0,  1.0, // top right
            1.0, -1.0, // bottom right
        ];

        let indexData = [
            0, 2, 1,
            2, 3, 1
        ];

        let vbo = this.createVBO(this.gl, vertexData);
        this.ibo = this.createIBO(this.gl, indexData);
        // this.gl.bindBuffer(this.gl.ELEMENT_ARRAY_BUFFER, this.ibo);

        let attLocation = new Array();
        let attStride = new Array();

        attLocation[0] = this.getAttribLocation(program, 'position');
        attStride[0] = 2;
        this.setAttribute(this.gl, [vbo], attLocation, attStride);

        //uniform
        this.uniLocation = [];
        this.uniLocation[0] = this.getUniformLocation(program, 'metaballs');
        this.uniLocation[1] = this.getUniformLocation(program, 'resolution');
        this.uniLocation[2] = this.getUniformLocation(program, 'bgColor');
        this.uniLocation[3] = this.getUniformLocation(program, 'metaballColors');
    }

    initWebGLForGrayscale(){
        // 正射影で板ポリゴンをレンダリングするシェーダ
        let oVertexShader = this.compileShader(this.gVertexShaderSrc, this.gl.VERTEX_SHADER);
        let oFragmentShader = this.compileShader(this.gFragmentShaderSrc, this.gl.FRAGMENT_SHADER);
        let oProgram = this.oProgram = this.gl.createProgram();
        this.gl.attachShader(oProgram, oVertexShader);
        this.gl.attachShader(oProgram, oFragmentShader);
        this.gl.linkProgram(oProgram);

        //attribute
        let oPosition = [
            -1.0,  1.0,  0.0,
            1.0,  1.0,  0.0,
            -1.0, -1.0,  0.0,
            1.0, -1.0,  0.0
        ];

        let oTexCoord = [
            1.0, 1.0,
            0.0, 1.0,
            1.0, 0.0,
            0.0, 0.0
        ];

        let oIndex = this.oIndex = [
            0, 2, 1,
            2, 3, 1
        ];

        let vbo = this.createVBO(this.gl, oPosition);
        let texCoordVbo = this.createVBO(this.gl, oTexCoord);
        this.oVBOList = [vbo, texCoordVbo];

        let ibo = this.oIBO = this.createIBO(this.gl, oIndex);
        let attLocation = this.oAttLocation = new Array();
        let attStride = this.oAttStride = new Array();

        attLocation[0] = this.getAttribLocation(oProgram, 'position');
        attLocation[1] = this.getAttribLocation(oProgram, 'texCoord');
        attStride[0] = 3;
        attStride[1] = 2;

        this.setAttribute(this.gl, this.oVBOList, attLocation, attStride);

        //uniform
        this.oUniLocation = [];
        this.oUniLocation[0] = this.getUniformLocation(oProgram, 'mvpMatrix');
        this.oUniLocation[1] = this.getUniformLocation(oProgram, 'texture');
        this.oUniLocation[2] = this.getUniformLocation(oProgram, 'grayScale');
    }
/*

    initWebGLForBlur() {
        // 正射影で板ポリゴンをレンダリングするシェーダ
        let oVertexShader = this.compileShader(this.bVertexShaderSrc, this.gl.VERTEX_SHADER);
        let oFragmentShader = this.compileShader(this.bFragmentShaderSrc, this.gl.FRAGMENT_SHADER);
        let oProgram = this.oProgram = this.gl.createProgram();
        this.gl.attachShader(oProgram, oVertexShader);
        this.gl.attachShader(oProgram, oFragmentShader);
        this.gl.linkProgram(oProgram);

        // 頂点の位置
        let oPosition = [
            -1.0,  1.0,  0.0,
            1.0,  1.0,  0.0,
            -1.0, -1.0,  0.0,
            1.0, -1.0,  0.0
        ];

        // 頂点色
        let oColor = [
            1.0, 1.0, 1.0, 1.0,
            1.0, 1.0, 1.0, 1.0,
            1.0, 1.0, 1.0, 1.0,
            1.0, 1.0, 1.0, 1.0
        ];

        // 頂点インデックス
        let oIndex = this.oIndex = [
            0, 1, 2,
            3, 2, 1
        ];

        let vbo = this.createVBO(this.gl, oPosition);
        let colorVbo = this.createVBO(this.gl, oColor);
        this.oVBOList = [vbo, colorVbo];

        let ibo = this.oIBO = this.createIBO(this.gl, oIndex);

        let attLocation = this.oAttLocation = new Array();
        let attStride = this.oAttStride = new Array();

        attLocation[0] = this.getAttribLocation(oProgram, 'position');
        attLocation[1] = this.getAttribLocation(oProgram, 'color');
        attStride[0] = 3;
        attStride[1] = 4;

        this.setAttribute(this.gl, this.oVBOList, attLocation, attStride);

        //uniform
        this.oUniLocation = [];
        this.oUniLocation[0] = this.getUniformLocation(oProgram, 'mvpMatrix');
        this.oUniLocation[1] = this.getUniformLocation(oProgram, 'texture');
        this.oUniLocation[2] = this.getUniformLocation(oProgram, 'useBlur');
    }
*/

    initWebGLForBlur() {
        // 正射影で板ポリゴンをレンダリングするシェーダ
        let oVertexShader = this.compileShader(this.bVertexShaderSrc, this.gl.VERTEX_SHADER);
        let oFragmentShader = this.compileShader(this.bFragmentShaderSrc, this.gl.FRAGMENT_SHADER);
        let oProgram = this.oProgram = this.gl.createProgram();
        this.gl.attachShader(oProgram, oVertexShader);
        this.gl.attachShader(oProgram, oFragmentShader);
        this.gl.linkProgram(oProgram);

        // 頂点の位置
        let oPosition = [
            -1.0,  1.0,  0.0,
            1.0,  1.0,  0.0,
            -1.0, -1.0,  0.0,
            1.0, -1.0,  0.0
        ];

        //UV
        let oTexCoord = [
            0.0, 0.0,
            1.0, 0.0,
            0.0, 1.0,
            1.0, 1.0
        ];

        // 頂点インデックス
        let oIndex = this.oIndex = [
            0, 2, 1,
            2, 3, 1
        ];

        let vbo = this.createVBO(this.gl, oPosition);
        let texCoordVbo = this.createVBO(this.gl, oTexCoord);
        this.oVBOList = [vbo, texCoordVbo];

        let ibo = this.oIBO = this.createIBO(this.gl, oIndex);

        let attLocation = this.oAttLocation = new Array();
        let attStride = this.oAttStride = new Array();

        attLocation[0] = this.getAttribLocation(oProgram, 'position');
        attLocation[1] = this.getAttribLocation(oProgram, 'texCoord');
        attStride[0] = 3;
        attStride[1] = 2;

        this.setAttribute(this.gl, this.oVBOList, attLocation, attStride);

        //uniform
        this.oUniLocation = [];
        this.oUniLocation[0] = this.getUniformLocation(oProgram, 'mvpMatrix');
        this.oUniLocation[1] = this.getUniformLocation(oProgram, 'texture');
        this.oUniLocation[2] = this.getUniformLocation(oProgram, 'gaussian');
        this.oUniLocation[3] = this.getUniformLocation(oProgram, 'weight');
        this.oUniLocation[4] = this.getUniformLocation(oProgram, 'horizontal');

    }

    initMetaball(){
        for( let i = 0, len = this.numMetaballs; i < len; i++ ){
            // let radius = Math.random() * 100 + 120;
            let radius = this.sw * (0.075 + Math.random() * 0.06);
            let radius2 = radius * 0.75;
            this.metaBalls.push({
                x: Math.random() * (this.sw - 2 * radius) + radius,
                y: Math.random() * (this.sh - 2 * radius) + radius,
                vx: (Math.random() - 0.5) * 3,
                vy: (Math.random() - 0.5) * 3,
                r: radius2
            });

            this.defMetaBallsRadius.push(radius2);
        }
    }

    changeToNormal(){
        if(this.bgPatern != 1){
            let dr = 2;
            let ease = Quart.easeOut;
            TweenMax.to(this, dr, {currentR:this.NORMAL_R, currentG:this.NORMAL_G, currentB:this.NORMAL_B, ease:ease});

            for (let i = 0; i < this.numMetaballs; i++) {
                let mb = this.metaBalls[i];
                TweenMax.killTweensOf(mb);
                TweenMax.to(mb, dr, {r:this.defMetaBallsRadius[i], ease:Quart.easeOut});
            }

            this.bgPatern = 1;
            this.canvasContainer.classList.remove("darker");
        }
    }

    changeToFooter(){
        if(this.bgPatern != 2){
            let dr = 2;
            let ease = Quart.easeOut;
            TweenMax.to(this, dr, {currentR:this.FOOTER_R, currentG:this.FOOTER_G, currentB:this.FOOTER_B, ease:ease});

            for (let i = 0; i < this.numMetaballs; i++) {
                let mb = this.metaBalls[i];
                TweenMax.killTweensOf(mb);
                TweenMax.to(mb, dr, {r:this.defMetaBallsRadius[i] * 2.2, ease:Quart.easeOut});
            }

            this.bgPatern = 2;
            this.canvasContainer.classList.add("darker");
        }
    }

    update(){
        this.render();
    }

    renderGaussian(g, h, weight){
        // バッファを初期化
        this.gl.clearColor(0.0, 0.0, 0.0, 1.0);
        this.gl.clearDepth(1.0);
        this.gl.clear(this.gl.COLOR_BUFFER_BIT | this.gl.DEPTH_BUFFER_BIT);

        // 板ポリゴンのレンダリング
        this.setAttribute(this.gl, this.oVBOList, this.oAttLocation, this.oAttStride);
        this.gl.bindBuffer(this.gl.ELEMENT_ARRAY_BUFFER, this.oIBO);
        this.gl.uniformMatrix4fv(this.oUniLocation[0], false, this.tmpMatrix);
        this.gl.uniform1i(this.oUniLocation[1], 0);
        this.gl.uniform1i(this.oUniLocation[2], g);
        this.gl.uniform1fv(this.oUniLocation[3], weight);
        this.gl.uniform1i(this.oUniLocation[4], h);
        this.gl.drawElements(this.gl.TRIANGLES, this.oIndex.length, this.gl.UNSIGNED_SHORT, 0);
    }

    render(){

        // プログラムオブジェクトの選択
        this.gl.useProgram(this.program);

        // フレームバッファのバインド
        // this.gl.bindFramebuffer(this.gl.FRAMEBUFFER, this.fBuffer.f);

        for( let i = 0, len = this.numMetaballs; i < len; i++ ){
            let metaball = this.metaBalls[i];
            metaball.x += metaball.vx;
            metaball.y += metaball.vy;

            if (metaball.x < metaball.r || metaball.x > this.sw - metaball.r) metaball.vx *= -1;
            if (metaball.y < metaball.r || metaball.y > this.sh - metaball.r) metaball.vy *= -1;
        }

        let metaballPositionToGPU = new Float32Array(3 * this.numMetaballs);

        for (let i = 0; i < this.numMetaballs; i++) {
            let baseIndex = 3 * i;
            let mb = this.metaBalls[i];
            metaballPositionToGPU[baseIndex + 0] = mb.x;
            metaballPositionToGPU[baseIndex + 1] = mb.y;
            metaballPositionToGPU[baseIndex + 2] = mb.r;
        }
        this.gl.uniform3fv(this.uniLocation[0], metaballPositionToGPU);
        this.gl.uniform2fv(this.uniLocation[1], [this.sw, this.sh]);
        this.gl.uniform3fv(this.uniLocation[2], [this.currentR, this.currentG, this.currentB]);

        let metaballColorToGPU = new Float32Array(3 * this.METABALL_COLOR_LEN);

        for( let i = 0, len = this.METABALL_COLOR_LEN; i < len; i++ ){
            let baseIndex = 3 * i;
            let color = this.currentMetaballColors[i];
            metaballColorToGPU[baseIndex + 0] = color.r;
            metaballColorToGPU[baseIndex + 1] = color.g;
            metaballColorToGPU[baseIndex + 2] = color.b;
        }

        this.gl.uniform3fv(this.uniLocation[3], metaballColorToGPU);

        //Draw
        this.gl.drawArrays(this.gl.TRIANGLE_STRIP, 0, 4);


/*
        //オフスクリーンレンダリング（グレースケール）
        // プログラムオブジェクトの選択
        this.gl.useProgram(this.oProgram);

        // フレームバッファのバインドを解除
        this.gl.bindFramebuffer(this.gl.FRAMEBUFFER, null);

        // canvas を初期化
        this.gl.clearColor(0.0, 0.0, 0.0, 1.0);
        this.gl.clearDepth(1.0);
        this.gl.clear(this.gl.COLOR_BUFFER_BIT | this.gl.DEPTH_BUFFER_BIT);

        // フレームバッファをテクスチャとして適用
        this.gl.activeTexture(this.gl.TEXTURE0);
        this.gl.bindTexture(this.gl.TEXTURE_2D, this.fBuffer.t);

        // エレメントからグレイスケール化するかどうかのフラグを取得
        let gray = true;

        // 板ポリゴンのレンダリング
        this.setAttribute(this.gl, this.oVBOList, this.oAttLocation, this.oAttStride);
        this.gl.bindBuffer(this.gl.ELEMENT_ARRAY_BUFFER, this.oIBO);
        this.gl.uniformMatrix4fv(this.oUniLocation[0], false, this.tmpMatrix);
        this.gl.uniform1i(this.oUniLocation[1], 0);
        this.gl.uniform1i(this.oUniLocation[2], gray);
        this.gl.drawElements(this.gl.TRIANGLES, this.oIndex.length, this.gl.UNSIGNED_SHORT, 0);
*/


/*
        //オフスクリーンレンダリング（ブラー）
        // プログラムオブジェクトの選択
        this.gl.useProgram(this.oProgram);

        // フレームバッファのバインドを解除
        this.gl.bindFramebuffer(this.gl.FRAMEBUFFER, null);

        // canvas を初期化
        this.gl.clearColor(0.0, 0.0, 0.0, 1.0);
        this.gl.clearDepth(1.0);
        this.gl.clear(this.gl.COLOR_BUFFER_BIT | this.gl.DEPTH_BUFFER_BIT);

        // フレームバッファをテクスチャとして適用
        this.gl.activeTexture(this.gl.TEXTURE0);
        this.gl.bindTexture(this.gl.TEXTURE_2D, this.fBuffer.t);

        // ブラーフィルターをかけるかどうかの真偽値
        this.useBlur = true;

        // 板ポリゴンのレンダリング
        this.setAttribute(this.gl, this.oVBOList, this.oAttLocation, this.oAttStride);
        this.gl.bindBuffer(this.gl.ELEMENT_ARRAY_BUFFER, this.oIBO);
        this.gl.uniformMatrix4fv(this.oUniLocation[0], false, this.tmpMatrix);
        this.gl.uniform1i(this.oUniLocation[1], 0);
        this.gl.uniform1i(this.oUniLocation[2], this.useBlur);
        this.gl.drawElements(this.gl.TRIANGLES, this.oIndex.length, this.gl.UNSIGNED_SHORT, 0);
*/
/*
        //オフスクリーンレンダリング（ガウシアンフィルタ）
        // プログラムオブジェクトの選択
        this.gl.useProgram(this.oProgram);

        // 正射影用の座標変換行列
        // this.m.lookAt([0.0, 0.0, 0.5], [0.0, 0.0, 0.0], [0.0, 1.0, 0.0], this.vMatrix);
        // this.m.ortho(-1.0, 1.0, 1.0, -1.0, 0.1, 1, this.pMatrix);
        // this.m.multiply(this.pMatrix, this.vMatrix, this.tmpMatrix);


        // テクスチャの適用
        this.gl.activeTexture(this.gl.TEXTURE0);
        this.gl.bindTexture(this.gl.TEXTURE_2D, this.fBuffer.t);


        // gaussianフィルタの重み係数を算出
        let weight = new Array(10);
        let t = 0.0;
        let range = 10000;
        let d = range * range / 100;
        for(let i = 0; i < weight.length; i++){
            let r = 1.0 + 2.0 * i;
            let w = Math.exp(-0.5 * (r * r) / d);
            weight[i] = w;
            if(i > 0){w *= 2.0;}
            t += w;
        }
        for(let i = 0; i < weight.length; i++){
            weight[i] /= t;
        }


        //フィルタリングするかどうか
        let gaussian = true;

        if(gaussian){
            // フレームバッファのバインドを変更
            this.gl.bindFramebuffer(this.gl.FRAMEBUFFER, this.fBuffer2.f);

            // レンダリング(横方向ブラー)
            this.renderGaussian(true, true, weight);

            // テクスチャを変更
            this.gl.bindTexture(this.gl.TEXTURE_2D, this.fBuffer2.t);

            // フレームバッファのバインド解除
            this.gl.bindFramebuffer(this.gl.FRAMEBUFFER, null);

            // レンダリング(縦方向ブラー)
            this.renderGaussian(true, false, weight);
        }
*/
    }

    start(){
        this.changeMetaballColors();
    }

    changeMetaballColors(){
        let targets = [];

        if(this.currentMetaballColorPatern === 1) targets = [1,2,3,0];
        else if(this.currentMetaballColorPatern === 2) targets = [2,3,0,1];
        else if(this.currentMetaballColorPatern === 3) targets = [3,0,1,2];
        else if(this.currentMetaballColorPatern === 4) targets = [0,1,2,3];

        for( let i = 0, len = this.METABALL_COLOR_LEN; i < len; i++ ){
            let id = targets[i] + 1;
            let r = this["METABALL_COLOR_" + id + "R"];
            let g = this["METABALL_COLOR_" + id + "G"];
            let b = this["METABALL_COLOR_" + id + "B"];
            let obj;
            if(i === this.METABALL_COLOR_LEN - 1) obj = {r:r, g:g, b:b, ease:Linear.easeOut, onComplete:this.changeMetaballColors.bind(this)};
            else obj = {r:r, g:g, b:b, ease:Linear.easeOut};
            TweenMax.to(this.currentMetaballColors[i], 5, obj);
        }

        if(this.currentMetaballColorPatern === 4) this.currentMetaballColorPatern = 1;
        else this.currentMetaballColorPatern++;
    }

    scrollHandler(){

    }


    enterframe(){

    }

    enterframeThinOut(){
        this.update();
    }

    executeResize() {
        super.executeResize();

        if(this.hasTouch){
            this.sh = window.innerHeight + 80;
            this.shh = this.sh / 2;
        }

        this.canvas.width = this.sw;
        this.canvas.height = this.sh;

        if(this.gl) {
            this.defMetaBallsRadius = [];
            for( let i = 0, len = this.numMetaballs; i < len; i++ ){
                let mb = this.metaBalls[i];

                let radius;
                if(this.sw >= this.pack.BP) radius = this.sw * (0.08 + Math.random() * 0.03);
                else radius = this.sh * (0.08 + Math.random() * 0.03);

                let radius2 = radius * 0.75;
                mb.r = radius2;
                this.defMetaBallsRadius.push(radius2);
            }

            this.gl.viewport(0,0,this.sw,this.sh);

            if(this.fBuffer){
                this.fBuffer = this.createFramebuffer(this.gl, this.sw, this.sh);
            }
        }
    }
}