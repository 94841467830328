import TemplateContents from './TemplateContents';


export default class extends TemplateContents{
    constructor(param){
        super(param);
    }

    init() {
        this.pack = window.TIER4;
        this.sw, this.sh;
        this.enterframeID;

        this.setVars();
        this.setDom();
        this.bindResizeHandler = this.resizeHandler.bind(this);
        this.hasTouch = ("ontouchstart" in window);
        this.resizeEvent = 'resize';

        this.initEvents();

        this.initShader();
        this.initWebGL();
        this.initMetaball();
        this.update();
        this.resizeHandler();

        // this.pack.common.addScrollTarget(this);
        // this.pack.common.addEnterframeTarget(this);
    }

    reset(){
        super.reset();

        this.setVars();
        // this.pack.common.addScrollTarget(this);
        // this.pack.common.addEnterframeTarget(this);
    }

    destruct(){
        super.destruct();

        // this.pack.common.removeScrollTarget(this);
        // this.pack.common.removeEnterframeTarget(this);
    }

    setVars(){
        super.setVars();
        this.numMetaballs = 5;
        this.metaBalls = [];
        this.enabled = false;

        this.LOADING_R = 31;
        this.LOADING_G = 41;
        this.LOADING_B = 51;

        this.TRANSIT_R = 39;
        this.TRANSIT_G = 75;
        this.TRANSIT_B = 101;

        this.r = this.TRANSIT_R;
        this.g = this.TRANSIT_G;
        this.b = this.TRANSIT_B;
    }

    changeToTransitColor(){
        this.r = this.TRANSIT_R;
        this.g = this.TRANSIT_G;
        this.b = this.TRANSIT_B;
    }

    setDom(){
        super.setDom();

        this.canvasContainer = document.querySelector('#transition');
        this.canvas = document.querySelector('#transition canvas');

    }

    initEvents(){
        super.initEvents();

    }

    initShader() {
        this.vertexShaderSrc = `
            attribute vec2 position;
    
            void main() {
                // position specifies only x and y.
                // We set z to be 0.0, and w to be 1.0
                gl_Position = vec4(position, 0.0, 1.0);
            }
        `;

        this.fragmentShaderSrc = `
            precision highp float;
            
            const float WIDTH = ` + (this.sw >> 0) + `.0;
            const float HEIGHT = ` + (this.sh >> 0) + `.0;
            
            // float R = 31./255.;
            // float G = 41./255.;
            // float B = 51./255.;
            
            //lighter
            float R = `+ this.r + `.0/255.;
            float G = `+ this.g + `.0/255.;
            float B = `+ this.b + `.0/255.;
            
            uniform vec3 metaballs[` + this.numMetaballs + `];
            uniform vec2 resolution;
            
            void main(){
                float x = gl_FragCoord.x;
                float y = gl_FragCoord.y;
                
                float sum = 0.0;
                for (int i = 0; i < ` + this.numMetaballs + `; i++) {
                    vec3 metaball = metaballs[i];
                    float dx = metaball.x - x;
                    float dy = metaball.y - y;
                    float radius = metaball.z;                    
                    sum += (radius * radius) / (dx * dx + dy * dy);
                }
                
                if (sum >= 0.99) {
                    gl_FragColor = vec4(R, G, B, 1.0);
                    return;
                }
                
                gl_FragColor = vec4(0., 0., 0., 0.0);
            }
        `;
    }

    compileShader(shaderSource, shaderType) {
        let shader = this.gl.createShader(shaderType);
        this.gl.shaderSource(shader, shaderSource);
        this.gl.compileShader(shader);

        if (!this.gl.getShaderParameter(shader, this.gl.COMPILE_STATUS)) {
            throw "Shader compile failed with: " + this.gl.getShaderInfoLog(shader);
        }

        return shader;
    }

    getAttribLocation(program, name) {
        let attributeLocation = this.gl.getAttribLocation(program, name);
        if (attributeLocation === -1) {
            throw 'Can not find attribute ' + name + '.';
        }
        return attributeLocation;
    }

    getUniformLocation(program, name) {
        let uniformLocation = this.gl.getUniformLocation(program, name);
        if (uniformLocation === -1) {
            throw 'Can not find uniform ' + name + '.';
        }
        return uniformLocation;
    }

    initWebGL() {
        this.gl = this.canvas.getContext('webgl');
        let vertexShader = this.compileShader(this.vertexShaderSrc, this.gl.VERTEX_SHADER);
        let fragmentShader = this.compileShader(this.fragmentShaderSrc, this.gl.FRAGMENT_SHADER);
        let program = this.gl.createProgram();
        this.gl.attachShader(program, vertexShader);
        this.gl.attachShader(program, fragmentShader);
        this.gl.linkProgram(program);
        this.gl.useProgram(program);

        let vertexData = new Float32Array([
            -1.0,  1.0, // top left
            -1.0, -1.0, // bottom left
            1.0,  1.0, // top right
            1.0, -1.0, // bottom right
        ]);
        let vertexDataBuffer = this.gl.createBuffer();
        this.gl.bindBuffer(this.gl.ARRAY_BUFFER, vertexDataBuffer);
        this.gl.bufferData(this.gl.ARRAY_BUFFER, vertexData, this.gl.STATIC_DRAW);

        let positionHandle = this.getAttribLocation(program, 'position');
        this.gl.enableVertexAttribArray(positionHandle);
        this.gl.vertexAttribPointer(positionHandle,
            2, // position is a vec2
            this.gl.FLOAT, // each component is a float
            this.gl.FALSE, // don't normalize values
            2 * 4, // two 4 byte float components per vertex
            0 // offset into each span of vertex data
        );

        this.metaballsHandle = this.getUniformLocation(program, 'metaballs');
        this.resolutionHandle = this.getUniformLocation(program, 'resolution');
    }

    initMetaball(){
        let pos = [
            {x:-2000, y:0},
            {x:this.swh, y:this.shh},
            {x:this.sw + 2000, y:this.sh},
        ];
        for( let i = 0, len = this.numMetaballs; i < len; i++ ){
            // let radius = Math.random() * (this.swh / 3) + (this.swh / 1.5);
            let radius = this.swh / 2.5;
            let deg = Math.random() * 360;
            let rad = this.pack.d2r(deg);
            let x = this.swh + Math.cos(rad) * (this.sw * 2);
            let y = this.shh + Math.sin(rad) * (this.sh * 2);
            this.metaBalls.push({
                x: x,
                y: y,
                vx: (Math.random() - 0.5) * 3,
                vy: (Math.random() - 0.5) * 3,
                r: radius
            });
        }
    }

    transitIn(callback, scope){
        this.enabled = true;
        this.canvasContainer.classList.add("show");
        let dr = .8;
        let dr2 = .3;
        let radius = this.sw * .75;
        let defRadius = this.swh / 2.5;

        for( let i = 0, len = this.numMetaballs; i < len; i++ ){
            let item = this.metaBalls[i];
            let deg = Math.random() * 360;
            let rad = this.pack.d2r(deg);
            let x = this.swh + Math.cos(rad) * (this.sw / 5);
            let y = this.shh + Math.sin(rad) * (this.sh / 5);
            TweenMax.to(item, dr, {delay: .15 * i, x:x, y:y, ease:Quart.easeOut});
            if(i === this.numMetaballs - 1) {
                TweenMax.fromTo(item, dr2, {r:defRadius}, {delay: 1.0 + .15 * i, r:radius, ease:Quart.easeOut, onComplete:()=>{
                    if(scope) callback.call(scope);
                    else callback();
                }});
            }else TweenMax.fromTo(item, dr2, {r:defRadius}, {delay: 1.0 + .15 * i, r:radius, ease:Quart.easeOut});
        }
    }

    transitOut(){
        let dr = .9;
        let radius = this.swh / 2.0;
        for( let i = 0, len = this.numMetaballs; i < len; i++ ){
            let item = this.metaBalls[i];
            let deg = Math.random() * 360;
            let rad = this.pack.d2r(deg);
            let x = this.swh + Math.cos(rad) * (this.sw * 1.3);
            let y = this.shh + Math.sin(rad) * (this.sh * 1.3);
            TweenMax.to(item, dr, {delay:.01 * i, r:radius, ease:Quart.easeOut});
            if(i === this.numMetaballs - 1) {
                TweenMax.to(item, dr, {delay:.1 * i, x:x, y:y, ease:Quart.easeInOut, onComplete:()=>{
                    this.enabled = false;
                    this.canvasContainer.classList.remove("show");
                }});
            }else TweenMax.to(item, dr, {delay:.1 * i, x:x, y:y, ease:Quart.easeInOut});
        }
    }

    update(){
        this.render();
    }

    render(){
        if(!this.enabled) return;

        this.dataToSendToGPU = new Float32Array(3 * this.numMetaballs);

        for (let i = 0; i < this.numMetaballs; i++) {
            let baseIndex = 3 * i;
            let mb = this.metaBalls[i];
            this.dataToSendToGPU[baseIndex + 0] = mb.x;
            this.dataToSendToGPU[baseIndex + 1] = mb.y;
            this.dataToSendToGPU[baseIndex + 2] = mb.r;
        }
        this.gl.uniform3fv(this.metaballsHandle, this.dataToSendToGPU);
        this.gl.uniform2fv(this.resolutionHandle, [this.sw, this.sh]);

        //Draw
        this.gl.drawArrays(this.gl.TRIANGLE_STRIP, 0, 4);
    }

    start(){

    }

    scrollHandler(){

    }


    enterframe(){

    }

    enterframeThinOut(){
        if(this.enabled) this.update();
    }

    executeResize() {
        super.executeResize();

        this.canvas.width = this.sw;
        this.canvas.height = this.sh;

        if(this.gl) {
            this.gl.viewport(0,0,this.sw,this.sh);
        }
    }
}