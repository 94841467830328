import TemplateContents from './TemplateContents';
let MarkdownIt = require('markdown-it');

export default class extends TemplateContents{
    constructor(param){
        super(param);
    }

    init() {
        super.init();

        this.pack.common.addScrollTarget(this);
        // this.pack.common.addEnterframeTarget(this);
    }

    reset(){
        super.reset();

        this.setVars();
        this.pack.common.addScrollTarget(this);
        // this.pack.common.addEnterframeTarget(this);
    }

    destruct(){
        super.destruct();

        this.pack.header.unLockLang();

        this.pack.common.removeScrollTarget(this);
        // this.pack.common.removeEnterframeTarget(this);
    }

    destructAfterContentsOut(){
        this.next.classList.remove("simple");
        this.footerDOM.classList.remove("simple");
    }

    setVars(){
        super.setVars();
    }

    setDom(){
        super.setDom();

        this.next = document.querySelector('.next');
        this.footerDOM = document.querySelector('footer');

        this.dateDOM = document.querySelector('main.media_detail .kv h1 .date');
        this.categoryDOM = document.querySelector('main.media_detail .kv h1 .category');
        this.titDOM = document.querySelector('main.media_detail .kv h1 .tit');
        this.bodyTargetDOM = document.querySelector('main.media_detail .contents .contents_child .chunk');
    }

    initEvents(){
        super.initEvents();
    }

    getURLParam(v){
        if(window.location.search.length > 1){
            var query = window.location.search.substring(1);
            var parameters = query.split( '&' );
            var r;

            for( var i = 0; i < parameters.length; i++ )
            {
                var element = parameters[ i ].split( '=' );
                var paramName = decodeURIComponent( element[ 0 ] );
                var paramValue = decodeURIComponent( element[ 1 ] );

                if(paramName === v) r = paramValue;
            }

            return r;
        }
    }

    start(){
        super.start();

        this.next.classList.add("simple");
        this.footerDOM.classList.add("simple");

        this.loadContentful();

    }

    loadContentful(){
        let sysID = this.getURLParam("sys_id");
        this.category = this.getURLParam("category");
        let path = this.pack.getContentfulAPIMediaDetailPath(sysID);

        let urlList = [];
        urlList.push(path);

        let promiseList = [];
        this.jsonBaseList = [];

        urlList.forEach ((url, i) => {
            promiseList.push (
                fetch(url).then ((res) => {
                    return res.json();
                }).then( (res) => {
                    this.jsonBaseList[i]= res;
                })
            );
        });

        Promise
            .all(promiseList)
            .then (()=> {
                // trace("api loaded", this.jsonBaseList);
                this.setView();
                this.checkOtherLangs();
                this.setMeta();
            });
    }

    setView(){
        let item = this.jsonBaseList[0].items[0].fields;
        
        let date = this.pack.getFormatDate(item.releaseDate);
        let tit = this.pack.getTitle(item);
        let body = this.pack.getBody(item);

        let md = new MarkdownIt({html:true});
        let bodyResult = md.render(body);

        this.dateDOM.innerText = date;
        this.categoryDOM.innerText = this.category;
        this.titDOM.innerHTML = tit;
        this.bodyTargetDOM.innerHTML = bodyResult;
    }

    checkOtherLangs(){
        let item = this.jsonBaseList[0].items[0].fields;
        if(!item.bodyJa) this.pack.header.lockLang(0);
        if(!item.bodyEn) this.pack.header.lockLang(1);
        if(!item.bodyCn) this.pack.header.lockLang(2);
    }

    setMeta(){
        let headDOM = document.head;
        let titleDOM = headDOM.querySelector("title");
        let ogTitleDOM = headDOM.querySelector("meta[property='og:title']");
        let descriptionDOM = headDOM.querySelector("meta[name='description']");
        let ogDescriptionDOM = headDOM.querySelector("meta[property='og:description']");
        let ogImageDOM = headDOM.querySelector("meta[property='og:image']");
        let item = this.jsonBaseList[0].items[0].fields;

        let tit = this.pack.getTitle(item);
        let titResult = tit + " | TIER IV, Inc.";
        let body = this.pack.getBody(item);
        let md = new MarkdownIt({html:true, breaks:true});
        let bodyResult = md.render(body);
        bodyResult = bodyResult.replace(/(<([^>]+)>)/gi, '');        //HTMLを除外
        bodyResult = bodyResult.substr(0, 100);         //100文字に

        /*
        let thumbnail = item.thumbnail;
        let thumbnailAccessAPIPath = undefined;
        if(thumbnail) thumbnailAccessAPIPath = this.pack.getContentfulAPIImgAccessPath(item.thumbnail.sys.id);
        thumbnail = location.host + "/assets/img/media/default.jpg";
        trace(thumbnailAccessAPIPath);  //TODO
        */

        titleDOM.innerText = titResult;
        ogTitleDOM.setAttribute("content", titResult);
        descriptionDOM.setAttribute("content", bodyResult);
        ogDescriptionDOM.setAttribute("content", bodyResult);
    }

    scrollHandler(){
        this.pack.checkBG(this.next);
    }


    enterframe(){

    }

    enterframeThinOut(){

    }

    executeResize() {
        super.executeResize();
    }
}