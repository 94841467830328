import Carousel from './Carousel';

export default class extends Carousel{
    constructor(param){
        super(param);
    }

    setVars(){
        super.setVars();
    }

    //dom設定
    setDom(){
        super.setDom();

        this.baseBar = document.querySelector('main.ourteam > .section3 .section_child .ui .bar .base_bar');
        this.scrollBar = document.querySelector('main.ourteam > .section3 .section_child .ui .bar .scroll_bar');
        this.scrollBarChild = document.querySelector('main.ourteam > .section3 .section_child .ui .bar .scroll_bar_child');
    }

    //イベント
    initEvents(){
        super.initEvents();
    }

    enterframeThinOut() {
      let contentsMoveW = this.carousel.clientWidth - this.sw + this.minXAdjustPC;
      let currentScrollPer = Math.abs(this.swipeX) / contentsMoveW;

      let scrollBarW = this.scrollBar.clientWidth * this.scrolBarWidthPer;
      let baseBarW = this.baseBar.clientWidth;
      let scrollBarMoveW = baseBarW - scrollBarW;

      this.scrollBar.style.transform = 'translate3d(' + (scrollBarMoveW * currentScrollPer) + 'px, 0, 0)';
    }

    executeResize() {
      super.executeResize();

      let carouselWidth = this.carousel.clientWidth;    //Carousel全体の幅
      let displayAreaWidth = this.sw - this.sw * .15;   //表示エリアの幅（左は10vw、右は5vw、合計で15vwなのでthis.sw * .15)
      this.scrolBarWidthPer = displayAreaWidth / carouselWidth;

      this.scrollBarChild.style.transform = 'scale(' + this.scrolBarWidthPer + ')';
    }
}
