import TemplateContents from './TemplateContents';

export default class extends TemplateContents{
    constructor(param){
        super(param);
    }

    init() {
        super.init();

        this.pack.common.addScrollTarget(this);
        this.pack.common.addEnterframeTarget(this);
    }

    reset(){
        super.reset();

        this.setVars();
        this.pack.common.addScrollTarget(this);
        this.pack.common.addEnterframeTarget(this);
    }

    destruct(){
        super.destruct();

        this.pack.common.removeScrollTarget(this);
        this.pack.common.removeEnterframeTarget(this);
    }

    setVars(){
        super.setVars();

        // this.SECTION4_CIRCLE_RX = 406.779;
        // this.SECTION4_CIRCLE_RY = 264.174;
        this.section4CircleSpeedX = Math.random() * 2 + 1;
        this.section4CircleSpeedY = Math.random() * 2 + 1;
        this.MIN_SECTION4_CIRCLE_RX = 250;
        this.MAX_SECTION4_CIRCLE_RX = 400;

        this.MIN_SECTION4_CIRCLE_RY = 200;
        this.MAX_SECTION4_CIRCLE_RY = 250;

        this.currentSection4CircleRX = this.MIN_SECTION4_CIRCLE_RX;
        this.currentSection4CircleRY = this.MIN_SECTION4_CIRCLE_RY;

        this.currentSection4CircleEaseRX = this.MIN_SECTION4_CIRCLE_RX;
        this.currentSection4CircleEaseRY = this.MIN_SECTION4_CIRCLE_RY;
    }

    setDom(){
        super.setDom();

        this.next = document.querySelector('.next');

        this.section1 = document.querySelector('main.top .section1');
        this.section1Img = document.querySelector('main.top .section1 .img');
        this.section1ImgVideo = document.querySelector('main.top .section1 .img video');

        this.sectionChunk = document.querySelector('main.top .section_chunk');
        this.sectionChunkProducts = document.querySelector('main.top .section_chunk .products');
        this.contentfulPartners = document.querySelector('main.top .section3 .section_child .chunks .chunk ul li .tit.partners span');
        this.contentfulEngineers = document.querySelector('main.top .section3 .section_child .chunks .chunk ul li .tit.engineers span');
        this.contentfulLocations = document.querySelector('main.top .section3 .section_child .chunks .chunk ul li .tit.locations span');
        this.contentfulProjects = document.querySelector('main.top .section3 .section_child .chunks .chunk ul li .tit.projects span');
        this.contentfulTeammates = document.querySelector('main.top .section4 .section_child .chunk ul li .tit.teammates span');
        this.updatesContainer = document.querySelector('main.top .updates ul');

        this.section4Circle = document.querySelector('main.top #clip01 ellipse');

        this.circle1 = document.querySelector('main.top .section1 .section_child .c_chunk .right .circle');
        this.circle2 = document.querySelector('main.top .section2 .section_child .c_chunk .right .circle');
        this.circle3 = document.querySelector('main.top .section3 .section_child .imgs .circle');
        this.circle4 = document.querySelector('main.top .section3 .section_child > .circle');

        this.circle1.dataset.speed = 9;
        this.circle2.dataset.speed = 8;
        this.circle3.dataset.speed = 7;
        this.circle4.dataset.speed = 7;
    }

    initEvents(){
        super.initEvents();
    }

    start(){
        super.start();
        this.setValues();
    }

    setValues(){
        // let values = this.pack.contentfulJSONList[0].items[0].fields;
        let values = this.pack.contentfulJSONList['counts'].items[0].fields;
        this.contentfulPartners.innerText = this.pack.addComma(values.partner_count);
        this.contentfulEngineers.innerText = this.pack.addComma(values.engineer_count);
        this.contentfulLocations.innerText = this.pack.addComma(values.location_count);
        this.contentfulProjects.innerText = this.pack.addComma(values.project_count);
        this.contentfulTeammates.innerText = this.pack.addComma(values.teammates_count);

        for( let i = 0, len = 2; i < len; i++ ){
            let container = this.updatesContainer;
            let item = this.pack.mediaAllList[i];
            let dom = this.getUpdateItem(item.title, item.date, item.category, item.thumbnail, item.link, item.sys_id);
            container.appendChild(dom);

            if(item.thumbnailAccessAPIPath){
              this.pack.loadMediaArticleThumbnail(item, dom);
            }
        }
    }

    getUpdateItem(title, date, category, thumbnail, link, sys_id){
        /* pug src
            li
                a(href="")
                    .img
                        img(src="/assets/img/top/updates1.jpg" alt="" loading="lazy")
                    .chunk
                        .tit More the vehicle drives, safer & <br class="sp">smarter the system evolves.
                        .info
                            span.date APR 1, 2021
                            span.cate DOCUMENTS
         */


        if(!thumbnail) thumbnail = "/assets/img/media/default.jpg";
        let target = "_self";


        if(!link) {
            if(sys_id) {
                let lang;
                if(this.pack.LANG === "ja") lang = "/";
                else lang = "/" + this.pack.LANG + "/";
                link = lang + "media/detail/?sys_id=" + sys_id + "&category=" + category;
            }else link = "";
        }else{
            target = "_blank";
        }

        let item = document.createElement("li");
        let src = `
            <a href="${link}" target="${target}">
                <div class="img">
                    <img src="${thumbnail}" alt="" loading="lazy">
                </div>
                <div class="chunk">
                    <div class="tit font2">${title}</div>
                    <div class="info"><span class="date">${date}</span><span class="cate">${category}</span></div>
                </div>
            </a>
        `;
        item.innerHTML = src;

        return item;
    }

    scrollHandler(){
        this.pack.checkBG(this.next);
        this.pack.checkBottom() ? this.next.classList.add("show_fixed") : this.next.classList.remove("show_fixed");

        this.checkSection1();

        this.pack.commonParallax(this.circle1, this.sh, 90);
        this.pack.commonParallax(this.circle2, this.sh, 90);
        this.pack.commonParallax(this.circle3, this.sh, 90);
        this.pack.commonParallax(this.circle4, this.sh, 90);
    }

    checkSection1(){
        let sec1Y = this.section1.getBoundingClientRect().top;

        if(sec1Y <= this.sh && sec1Y >= 0){
            let width, width2, borderRadius, difScale;
            let maxPer;
            if(this.sw >= this.pack.BP){
                width = 90;
                borderRadius = 80;
                maxPer = (this.sw - 80) / this.sw * 100;    //左右に40px余白儲ける
            }else{
                width = 89;
                borderRadius = 34;
                maxPer = 100;
            }

            difScale = maxPer / width - 1;
            width2 = maxPer - width;

            let moveH = this.sh;
            let currentY = this.sh - sec1Y;
            let normal = currentY / moveH;
            let currentScale = 1 + difScale * (1 - normal);

            this.section1Img.style.width = width + width2 * normal + "vw";
            this.section1Img.style["border-radius"] = borderRadius - borderRadius * normal + "px";
            this.section1ImgVideo.style.transform = "scale(" + currentScale + ")";
        }
    }



    enterframe(){

    }

    enterframeThinOut(){
        this.section4Circle.style.rx = this.currentSection4CircleEaseRX;
        this.section4Circle.style.ry = this.currentSection4CircleEaseRY;

        this.currentSection4CircleRX += this.section4CircleSpeedX;
        this.currentSection4CircleRY += this.section4CircleSpeedY;

        this.currentSection4CircleEaseRX += (this.currentSection4CircleRX - this.currentSection4CircleEaseRX) / 10;
        this.currentSection4CircleEaseRY += (this.currentSection4CircleRY - this.currentSection4CircleEaseRY) / 10;

        if(this.currentSection4CircleRX >= this.MAX_SECTION4_CIRCLE_RX) this.section4CircleSpeedX *= -1;
        if(this.currentSection4CircleRY >= this.MAX_SECTION4_CIRCLE_RY) this.section4CircleSpeedY *= -1;
        if(this.currentSection4CircleRX <= this.MIN_SECTION4_CIRCLE_RX) this.section4CircleSpeedX *= -1;
        if(this.currentSection4CircleRY <= this.MIN_SECTION4_CIRCLE_RY) this.section4CircleSpeedY *= -1;
    }

    executeResize() {
        super.executeResize();
    }
}
