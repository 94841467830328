import TemplateContents from './TemplateContents';
import Carousel from './Carousel';

export default class extends TemplateContents {
  constructor(param){
    super(param);
  }

  init() {
    super.init();
    this.pack.common.addScrollTarget(this);
    this.pack.common.addEnterframeTarget(this);

    this.setCarousel();
    this.setModal();
  }

  reset() {
    super.reset();

    this.setVars();
    this.pack.common.addScrollTarget(this);
    this.pack.common.addEnterframeTarget(this);

    this.resetCarousel();
    this.setModal();
  }

  destruct() {
    super.destruct();

    document.body.classList.remove("no_hidden");
    document.body.classList.remove("no_hidden_sp");

    this.carousel.destruct();

    document.removeEventListener("mousemove", this.bindMouseMoveHandler);

    for (let i = 0, len = this.historyH3.length; i < len; i++) {
      let item = this.historyH3[i];
      item.removeEventListener("mouseover", this.bindMouseOverHistoryH3Handler);
      item.removeEventListener("mouseout", this.bindMouseOutHistoryH3Handler);
    }

    this.pack.common.removeScrollTarget(this);
    this.pack.common.removeEnterframeTarget(this);

    this.destructModal();
  }

  setVars() {
    super.setVars();
    this.isHistoryHover = false;
    this.currentHoverHistory = null;
    this.mouseX = 0;
    this.mouseY = 0;
  }

  setDom() {
    super.setDom();

    this.next = document.querySelector('.next');

    this.coreValueWrap = document.querySelector('main.about .section3_wrap');
    this.coreValueCopy = document.querySelector('main.about .section3_wrap .section3 .section_child .copy span');
    this.coreValueH2 = document.querySelector('main.about .section3_wrap .section3 .section_child h2');
    this.coreValueChunks = document.querySelector('main.about .section3_wrap .section3 .section_child .chunks');
    this.coreValueChunk = document.querySelectorAll('main.about .section3_wrap .section3 .section_child .chunks .chunk');
    this.coreValueUI = document.querySelector('main.about .section3_wrap .section3 .section_child .ui');
    this.coreValueUILi = document.querySelectorAll('main.about .section3_wrap .section3 .section_child .ui ul li');

    this.history = document.querySelector('main.about .section5');
    this.historyH3 = document.querySelectorAll('main.about .section5 .section_child ul li h3.ym');
    this.historyImgs = document.querySelectorAll('main.about .section5 .section_child ul li .img');

    this.ourManagement = document.querySelector('main.about .section6');
  }

  initEvents() {
    super.initEvents();

    this.bindMouseMoveHandler = this.mouseMoveHandler.bind(this);
    this.bindMouseOverHistoryH3Handler = this.mouseOverHistoryH3Handler;
    this.bindMouseOutHistoryH3Handler = this.mouseOutHistoryH3Handler;

    document.addEventListener("mousemove", this.bindMouseMoveHandler);

    for (let i = 0, len = this.historyH3.length; i < len; i++) {
      let item = this.historyH3[i];
      item.addEventListener("mouseover", {handleEvent: this.bindMouseOverHistoryH3Handler, sc: this, id: i});
      item.addEventListener("mouseout", {handleEvent: this.bindMouseOutHistoryH3Handler, sc: this, id: i});
    }
  }

  mouseMoveHandler(event) {
    this.mouseX = event.clientX;
    this.mouseY = event.clientY;
  }

  mouseOverHistoryH3Handler(event) {
    let sc = this.sc;
    let id = this.id;
    sc.isHistoryHover = true;
    sc.currentHoverHistory = id;
    sc.historyImgs[id].classList.add("show");
  }

  mouseOutHistoryH3Handler(event) {
    let sc = this.sc;
    let id = this.id;
    sc.isHistoryHover = false;
    sc.historyImgs[id].classList.remove("show");
  }

  start() {
    super.start();
    document.body.classList.add("no_hidden");
    document.body.classList.add("no_hidden_sp");
  }

  setCarousel(){
    this.carousel = new Carousel(this.pack);
    this.carousel.setTarget(this.history);

    if(!this.ourManagement) return;
    this.carouselOurManagement = new Carousel(this.pack);
    this.carouselOurManagement.setTarget(this.ourManagement);
    this.carouselOurManagement.setMarginRightSP(true, 5.6);
    this.carouselOurManagement.setAdjustMinXSP(true, 22.1);
    this.carouselOurManagement.setOnlySP();
  }

  resetCarousel(){
    this.carousel.reset();
    this.carousel.setTarget(this.history);

    if(!this.ourManagement) return;
    this.carouselOurManagement.reset();
    this.carouselOurManagement.setTarget(this.ourManagement);
    this.carouselOurManagement.setMarginRightSP(true, 5.6);
    this.carouselOurManagement.setAdjustMinXSP(true, 22.1);
    this.carouselOurManagement.setOnlySP();
  }

  setModal() {
    // Get all the modals
    let modals = document.querySelectorAll('.modal');

    // Get the buttons that opens the modal
    let btns = document.querySelectorAll("button.thumb");
    let html = document.getElementsByTagName("html")[0];

    // Get the <span> elements that closes the modal
    let spans = document.getElementsByClassName("modal-close");

    // When the user clicks on the button, open the modal
    for (let i = 0; i < btns.length; i++) {
      btns[i].onclick = function (e) {
        e.preventDefault();
        let targetModal = document.querySelector(e.target.getAttribute("href"));
        targetModal.style.display = "block";
        html.style.overflowY = "hidden";
        html.style.height = "100%";
        TweenMax.delayedCall(.1, ()=>{
          targetModal.classList.add('show');
        });
      }
    }

    // When the user clicks on <span> (x), close the modal
    for (let i = 0; i < spans.length; i++) {
      spans[i].onclick = function () {
        for (let index in modals) {
          if (typeof modals[index].style !== 'undefined') {
            modals[index].style.display = "none";
            modals[index].classList.remove('show');
          }

          html.style.overflowY = "auto";
          html.style.height = "auto";
        }
      }
    }

    // When the user clicks anywhere outside of the modal, close it
    window.onclick = function (event) {
      if (event.target.classList.contains('modal')) {
        for (let index in modals) {
          if (typeof modals[index].style !== 'undefined') {
            modals[index].style.display = "none";
            modals[index].classList.remove('show');

            html.style.overflowY = "auto";
            html.style.height = "auto";
          }
        }
      }
    }
  }

  destructModal() {
    let html = document.getElementsByTagName("html")[0];
    html.style.overflowY = "auto";
    html.style.height = "auto";
  }

  scrollHandler() {
    this.pack.checkBG(this.next);
    this.pack.checkBottom() ? this.next.classList.add("show_fixed") : this.next.classList.remove("show_fixed");

    let coreValueY = this.coreValueWrap.getBoundingClientRect().top + this.sh * 2;
    let normal = coreValueY / (this.sh * 2);
    let normal2 = coreValueY / this.sh;
    let moveArea = this.sw + this.coreValueCopy.clientWidth;
    let x = normal * moveArea - this.coreValueCopy.clientWidth;
    this.coreValueCopy.style.transform = "translate3d(" + x + "px,0,0)";
    if (normal <= 0) {
      this.coreValueH2.classList.add("show");
      this.coreValueUI.classList.add("show");
      // if(normal <= -1.6) normal = -1.6;
      let y = this.sh + (normal2 / 1.5) * this.coreValueChunks.clientHeight;
      this.coreValueChunks.style.transform = "translate3d(0," + y + "px,0)";

      let current = 0;
      for (let i = 0, len = this.coreValueChunk.length; i < len; i++) {
        let item = this.coreValueChunk[i];
        let y = item.getBoundingClientRect().top;
        if (y <= this.sh) current = i;
      }
      this.setCurrentCoreValue(current);
    } else {
      this.coreValueH2.classList.remove("show");
      this.coreValueUI.classList.remove("show");
      this.coreValueChunks.style.transform = "translate3d(0," + this.sh + "px,0)";
      this.setCurrentCoreValue(0);
    }
  }

  setCurrentCoreValue(id) {
    for (let i = 0, len = this.coreValueUILi.length; i < len; i++) {
      let item = this.coreValueUILi[i];
      item.classList.remove("current");
    }

    this.coreValueUILi[id].classList.add("current");
  }

  enterframe() {

  }

  enterframeThinOut() {
    if (this.isHistoryHover) {
      let img = this.historyImgs[this.currentHoverHistory];
      let h3 = this.historyH3[this.currentHoverHistory];
      let h3BoudningClientRect = h3.getBoundingClientRect();
      let x = this.mouseX - h3BoudningClientRect.left;
      let y = this.mouseY - h3BoudningClientRect.top - 50;
      img.style.transform = "translate3d(" + x + "px, " + y + "px, 0)";
    }
  }

  executeResize() {
    super.executeResize();
  }
}
