import TemplateContents from './TemplateContents';
import Top from "./Top";
import About from "./About";
import Opensource from "./Opensource";
import Products from "./Products";
import Alliance from "./Alliance";
import Ourteam from "./Ourteam";
import Career from "./Career";
import Media from "./Media";

export default class extends TemplateContents{
    constructor(param){
        super(param);
    }

    init() {
        super.init();

        // this.pack.common.addScrollTarget(this);
        // this.pack.common.addEnterframeTarget(this);
    }

    reset(){
        super.reset();

        this.setVars();
        // this.pack.common.addScrollTarget(this);
        // this.pack.common.addEnterframeTarget(this);
    }

    destruct(){
        super.destruct();

        // this.pack.common.removeScrollTarget(this);
        // this.pack.common.removeEnterframeTarget(this);
    }

    setVars(){
        super.setVars();
    }

    setDom(){
        super.setDom();
        this.headerMenu = document.querySelectorAll('header .header_menu ul li');
        this.grill = document.querySelector('header .grill');
        this.mainMenuWrap = document.querySelector('header .main_menu');
        this.mainMenu = document.querySelector('header .main_menu .main');
        this.mainMenuHeads = document.querySelectorAll('header .main_menu .main > ul > li .head');
        this.mainMenuList = document.querySelectorAll('header .main_menu .main > ul > li');
        this.mainMenuSubs = document.querySelectorAll('header .main_menu .main > ul > li > ul > li');

        this.subMenu = document.querySelector('header .main_menu .sub');
        this.subMenuSocials = document.querySelectorAll('header .main_menu .sub .social li');
        this.subMenuOthers = document.querySelectorAll('header .main_menu .sub .others li');

        this.bgImg = document.querySelector('header .main_menu .bg_img');
        this.lang = document.querySelector('header .main_menu .lang');
        this.langSelect = document.querySelector('header .main_menu .lang select');
        this.langSelectOptions = document.querySelectorAll('header .main_menu .lang select option');
        this.newsletter = document.querySelector('header .main_menu .newsletter');
        this.cp = document.querySelector('header .main_menu .cp');
    }

    initEvents(){
        super.initEvents();

        this.bindChangeLangHandler = this.changeLangHandler.bind(this);

        this.grill.addEventListener("click", this.clickGrillHandler.bind(this));

        for( let i = 0, len = this.mainMenuSubs.length; i < len; i++ ){
            let item = this.mainMenuSubs[i];
            item.addEventListener("click", ()=>{
                TweenMax.delayedCall(.1, ()=>{
                    if(!this.pack.barba.isTransition){
                        this.closeMenuExternal();
                    }
                });
            });
        }

        this.langSelect.addEventListener("change", this.bindChangeLangHandler);
    }

    changeLangHandler(event){
        let value = event.currentTarget.value;
        this.pack.setSessionStorage(value.toLowerCase());
        this.pack.changeLang(value);
    }

    lockLang(id){
        this.langSelectOptions[id].disabled = true;
    }

    unLockLang(){
        for( let i = 0, len = this.langSelectOptions.length; i < len; i++ ){
            let item = this.langSelectOptions[i];
            item.disabled = false;
        }
    }

    clickGrillHandler(event){
        if(this.mainMenuWrap.classList.contains("open")){
            this.closeMenuExternal();
        }else{
            this.openMenuExternal();
        }
    }

    openMenuExternal(){
        this.pack.lockHTML();
        this.mainMenuWrap.classList.add("open");
        this.setTransitionDelay();

        TweenMax.delayedCall(.1, ()=>{
            this.mainMenuWrap.classList.add("show");
            this.newsletter.classList.add("show");
            this.mainMenu.classList.add("show");
            this.subMenu.classList.add("show");
            this.cp.classList.add("show");
            this.lang.classList.add("show");
        });
        this.grill.classList.add("close");
    }

    closeMenuExternal(){
        this.pack.unlockHTML();
        this.resetTransitionDelay();
        this.mainMenuWrap.classList.remove("show");
        this.newsletter.classList.remove("show");
        this.mainMenu.classList.remove("show");
        this.subMenu.classList.remove("show");
        this.cp.classList.remove("show");
        this.lang.classList.remove("show");
        this.grill.classList.remove("close");
        TweenMax.delayedCall(.5, ()=>{this.mainMenuWrap.classList.remove("open");});
    }

    setCurrent(){
        let namespace = this.pack.current;
        let currentID;
        for( let i = 0, len = this.mainMenuList.length; i < len; i++ ){
            let item = this.mainMenuList[i];
            let item2 = this.headerMenu[i];
            item.classList.remove("current");
            item2.classList.remove("current");
        }


        if(namespace === 'top') currentID = -1;
        else if(namespace === 'about') currentID = 0;
        else if(namespace === 'opensource') currentID = 1;
        else if(namespace === 'products') currentID = 2;
        else if(namespace === 'alliance') currentID = 4;
        else if(namespace === 'ourteam') currentID = 5;
        //else if(namespace === 'career') currentID = 6;
        else if(namespace === 'media') currentID = 7;
        else if(namespace === 'media_detail') currentID = 7;
        else currentID = -1;

        if(currentID === -1) return;
        this.mainMenuList[currentID].classList.add("current");
        this.headerMenu[currentID].classList.add("current");
    }

    start(){

    }

    setTransitionDelay(){
        this.newsletter.style.transitionDelay = ".4s";

        for( let i = 0, len = this.mainMenuHeads.length; i < len; i++ ){
            let item = this.mainMenuHeads[i];
            item.style.transitionDelay = .3 + i * .07 + "s";
        }

        for( let i = 0, len = this.mainMenuList.length; i < len; i++ ){
            let items = this.mainMenuList[i].querySelectorAll("ul > li");
            for(let j = 0; j < items.length; j++){
                let item = items[j];
                item.style.transitionDelay = .3 + i * .07 + j * .05 + "s";
            }
        }

        for( let i = 0, len = this.subMenuSocials.length; i < len; i++ ){
            let item = this.subMenuSocials[i];
            item.style.transitionDelay = .6 + i * .05 + "s";
        }

        for( let i = 0, len = this.subMenuOthers.length; i < len; i++ ){
            let item = this.subMenuOthers[i];
            item.style.transitionDelay = .8 + i * .05 + "s";
        }

        this.bgImg.style.transitionDelay = "0.6s";
        this.lang.style.transitionDelay = "1.0s";
        this.cp.style.transitionDelay = "1.0s";
    }


    resetTransitionDelay(){
        this.newsletter.style.transitionDelay = "0s";

        for( let i = 0, len = this.mainMenuHeads.length; i < len; i++ ){
            let item = this.mainMenuHeads[i];
            item.style.transitionDelay = "0s";
        }

        for( let i = 0, len = this.mainMenuList.length; i < len; i++ ){
            let items = this.mainMenuList[i].querySelectorAll("ul > li");
            for(let j = 0; j < items.length; j++){
                let item = items[j];
                item.style.transitionDelay = "0s";
            }
        }

        for( let i = 0, len = this.subMenuSocials.length; i < len; i++ ){
            let item = this.subMenuSocials[i];
            item.style.transitionDelay = "0s";
        }

        for( let i = 0, len = this.subMenuOthers.length; i < len; i++ ){
            let item = this.subMenuOthers[i];
            item.style.transitionDelay = "0s";
        }

        this.bgImg.style.transitionDelay = "0s";
        this.lang.style.transitionDelay = "0s";
        this.cp.style.transitionDelay = "0s";
    }

    scrollHandler(){

    }


    enterframe(){

    }

    enterframeThinOut(){

    }

    executeResize() {
        super.executeResize();
    }
}
