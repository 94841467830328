import TemplateContents from './TemplateContents';
import Carousel from './Carousel';
import PickupCarousel from "./PickupCarousel";
import LifeAtTierIVCarousel from "./LifeAtTierIVCarousel";

export default class extends TemplateContents {
  constructor(param){
    super(param);
  }

  init() {
    super.init();

    this.pack.common.addScrollTarget(this);
    this.pack.common.addEnterframeTarget(this);

    this.setCarousel();
    this.setModal();
  }

  reset() {
    super.reset();

    this.setVars();
    this.pack.common.addScrollTarget(this);
    this.pack.common.addEnterframeTarget(this);

    this.resetCarousel();
    this.setModal();
  }

  destruct() {
    super.destruct();

    //this.carouselOurPeople.destruct();
    this.carouselSubsidiaries.destruct();
    this.carouselCapitalAlliance.destruct();

    this.pack.common.removeScrollTarget(this);
    this.pack.common.removeEnterframeTarget(this);

    this.destructModal();
  }

  setVars() {
    super.setVars();
    // this.peopleList = [];
    // this.peopleThumbnailAccessAPIPathList = [];
  }

  setDom() {
    super.setDom();

    this.next = document.querySelector('.next');

    this.section2List = document.querySelectorAll('main.ourteam > .section2');
    this.pickupCarouselTarget = document.querySelector('main.ourteam > .section3');
    this.subsidiariesCarouselTarget = document.querySelector('main.ourteam > .section4');
    this.capitalAllianceCarouselTarget = document.querySelector('main.ourteam > .section5');
    this.lifeAtTier4CarouselTarget = document.querySelector('main.ourteam > .section7');

    //this.peopleContainer = document.querySelector('main.ourteam > .section2 .section_child .content');
    this.pickupContainer = document.querySelector('main.ourteam > .section3 .section_child .content');
    this.lifeAtTier4Container = document.querySelector('main.ourteam > .section7 .section_child .content');
    this.lifeAtTier4CircleContainer = document.querySelector('main.ourteam > .section7 .section_child .ui .circles');
    this.section5Bottom = document.querySelector('main.ourteam > .section5 .bottom .bottom_child');
    this.section5BottomPC = document.querySelector('main.ourteam > .section5 .bottom .bottom_child .pc');
    this.section5BottomSP = document.querySelector('main.ourteam > .section5 .bottom .bottom_child .sp');
  }

  initEvents() {
    super.initEvents();
  }

  start() {
    super.start();
    this.setValues();
    //this.setPeople();
    //this.loadPeopleThumbnails();
  }

  setCarousel(){
    let section2Len = this.section2List.length;
    this.carouselSection2 = [];
    for( let i = 0, len = section2Len; i < len; i++ ){
      let carousel = new Carousel(this.pack);
      this.carouselSection2[i] = carousel;
      carousel.setTarget(this.section2List[i]);
      carousel.setMarginRightSP(true, 5.6);
      carousel.setAdjustMinXSP(true, 22.1);
      carousel.setOnlySP();
    }

    this.carouselSubsidiaries = new Carousel(this.pack);
    this.carouselSubsidiaries.setTarget(this.subsidiariesCarouselTarget);
    this.carouselSubsidiaries.setMarginRightSP(true, 5.6);
    this.carouselSubsidiaries.setAdjustMinXSP(true, 5.6);
    this.carouselSubsidiaries.setOnlySP();

    this.carouselCapitalAlliance = new Carousel(this.pack);
    this.carouselCapitalAlliance.setTarget(this.capitalAllianceCarouselTarget);
    this.carouselCapitalAlliance.setMarginRightSP(true, 5.6);
    this.carouselCapitalAlliance.setAdjustMinXSP(true, 5.6);
    this.carouselCapitalAlliance.setOnlySP();
  }

  resetCarousel(){
    let section2Len = this.section2List.length;
    for( let i = 0, len = section2Len; i < len; i++ ){
      let carousel = this.carouselSection2[i];
      carousel.reset();
      carousel.setTarget(this.section2List[i]);
      carousel.setMarginRightSP(true, 5.6);
      carousel.setAdjustMinXSP(true, 22.1);
      carousel.setOnlySP();
    }


    this.carouselSubsidiaries.reset();
    this.carouselSubsidiaries.setTarget(this.subsidiariesCarouselTarget);
    this.carouselSubsidiaries.setMarginRightSP(true, 5.6);
    this.carouselSubsidiaries.setAdjustMinXSP(true, 5.6);
    this.carouselSubsidiaries.setOnlySP();

    this.carouselCapitalAlliance.reset();
    this.carouselCapitalAlliance.setTarget(this.capitalAllianceCarouselTarget);
    this.carouselCapitalAlliance.setMarginRightSP(true, 5.6);
    this.carouselCapitalAlliance.setAdjustMinXSP(true, 5.6);
    this.carouselCapitalAlliance.setOnlySP();
  }

  setModal() {
    // Get all the modals
    let modals = document.querySelectorAll('.modal');

    // Get the buttons that opens the modal
    let btns = document.querySelectorAll("button.thumb");
    let html = document.getElementsByTagName("html")[0];

    // Get the <span> elements that closes the modal
    let spans = document.getElementsByClassName("modal-close");

    // When the user clicks on the button, open the modal
    for (let i = 0; i < btns.length; i++) {
      btns[i].onclick = function (e) {
        e.preventDefault();
        let targetModal = document.querySelector(e.target.getAttribute("href"));
        targetModal.style.display = "block";
        html.style.overflowY = "hidden";
        html.style.height = "100%";
        TweenMax.delayedCall(.1, ()=>{
          targetModal.classList.add('show');
        });
      }
    }

    // When the user clicks on <span> (x), close the modal
    for (let i = 0; i < spans.length; i++) {
      spans[i].onclick = function () {
        for (let index in modals) {
          if (typeof modals[index].style !== 'undefined') {
            modals[index].style.display = "none";
            modals[index].classList.remove('show');
          }

          html.style.overflowY = "auto";
          html.style.height = "auto";
        }
      }
    }

    // When the user clicks anywhere outside of the modal, close it
    window.onclick = function (event) {
      if (event.target.classList.contains('modal')) {
        for (let index in modals) {
          if (typeof modals[index].style !== 'undefined') {
            modals[index].style.display = "none";
            modals[index].classList.remove('show');

            html.style.overflowY = "auto";
            html.style.height = "auto";
          }
        }
      }
    }
  }

  destructModal() {
    let html = document.getElementsByTagName("html")[0];
    html.style.overflowY = "auto";
    html.style.height = "auto";
  }

  setValues() {
/*
    //People
    this.peopleList = [];
    let items = this.pack.contentfulJSONList['people'].items;
    for (let i = 0, len = items.length; i < len; i++) {
      let item = items[i].fields;
      let name = this.pack.getName(item);
      let position = this.pack.getPosition(item);
      let thumbnailAccessAPIPath = this.pack.getContentfulAPIImgAccessPath(item.thumbnail.sys.id);
      this.peopleList[i] = {name: name, position: position};
      this.peopleThumbnailAccessAPIPathList[i] = thumbnailAccessAPIPath;
    }
*/

    //LIFE AT TIER IV
    this.lifeAtTier4ThumbnailAccessAPIPathList = [];
    // let items = this.pack.contentfulJSONList[8].items;
    let items = this.pack.contentfulJSONList['life_at_tier_4'].items;
    for( let i = 0, len = items.length; i < len; i++ ){
      let item = items[i].fields;
      let caption = this.pack.getData(item, 'caption');
      let url = this.pack.getData(item, 'url');
      let thumbnailAccessAPIPath = this.pack.getContentfulAPIImgAccessPath(item.image.sys.id);

      let dom = this.getLifeAtTier4Item(i, caption, url);
      this.lifeAtTier4Container.appendChild(dom);
      this.lifeAtTier4ThumbnailAccessAPIPathList[i] = thumbnailAccessAPIPath;
    }

    if(!this.carouselLifeAtTier4) this.carouselLifeAtTier4 = new LifeAtTierIVCarousel(this.pack);
    else this.carouselLifeAtTier4.reset();
    this.carouselLifeAtTier4.setTarget(this.lifeAtTier4CarouselTarget);
    this.carouselLifeAtTier4.setMarginRightPC(true, 2);
    this.carouselLifeAtTier4.setMarginRightSP(true, 5.6);
    this.carouselLifeAtTier4.setAdjustMinXPC(true, 48);
    this.carouselLifeAtTier4.setAdjustMinXSP(true, 5.6);
    this.carouselLifeAtTier4.setNoSwipe();

    this.loadLifeAtTier4Thumbnails();


    //Pick up
    let len = this.pack.pickupList.length;
    for (let i = 0; i < len; i++) {
      let item = this.pack.pickupList[i];
      let dom = this.getPickupItem(item.title, item.date, item.category, item.thumbnail, item.link);

      this.pickupContainer.appendChild(dom);

      if (item.thumbnailAccessAPIPath) {
        this.pack.loadMediaArticleThumbnail(item, dom);
      }
    }

    if(!this.carouselPickup) this.carouselPickup = new PickupCarousel(this.pack);
    else this.carouselPickup.reset();
    this.carouselPickup.setTarget(this.pickupCarouselTarget);
    this.carouselPickup.setMarginRightPC(true, 5);
    this.carouselPickup.setAdjustMinXPC(true, 10);
    this.carouselPickup.setOnlyPC();
  }

  getLifeAtTier4Item(id, caption, url){
    /* pug src
    .item.carousel_item.current
      a(href='' target='_blank')
        .img
          img(src='/_post/ourteam/ourteam_live.jpg' alt='')
      .txt.font2 ⾃動運転⾞両及びサービスの社会実装を加速させる <a href='' target='_blank'>MORE DETAIL</a>
     */

    let item = document.createElement("div");
    item.classList.add('item');
    item.classList.add('carousel_item');
    if(id === 0) item.classList.add('current');
    let src;

    if(url){
      src = `
        <a href="${url}" target="_blank">
          <div class="img">
              <img src="/assets/img/media/default.jpg" alt="">
          </div>
        </a>
        <div class="txt font2">${caption} <a href="${url}" target="_blank">MORE DETAIL</a></div>
    `;

    }else{
      src = `
        <div class="img">
          <img src="/assets/img/media/default.jpg" alt="">
        </div>
        <div class="txt font2">${caption}</div>
    `;
    }


    item.innerHTML = src;

    //ui circle
    let circle = document.createElement("div");
    circle.classList.add('circle');
    if(id === 0) circle.classList.add('current');
    this.lifeAtTier4CircleContainer.appendChild(circle);

    return item;
  }

  getPickupItem(title, date, category, thumbnail, link) {
    /* pug src
        article.carousel_item
            a(href="" target="_blank")
                .thumb
                    img(src="/assets/img/ourteam/sec3_1.jpg" alt="")
                p.txt
                    | More the vihicle drives, safer & smarter the system evolves.
                p.info
                    span.date APR 1, 2021
                    span.category TECH BLOG
     */

    if (!thumbnail) {
      thumbnail = "/assets/img/media/default.jpg";
    }

    if (!link) link = "";
    let item = document.createElement("article");
    item.classList.add('carousel_item');
    let src = `
            <a href="${link}" target="_blank">
                <div class="thumb"><img src="${thumbnail}" alt="" loading="lazy"></div>
                <p class="txt font2">${title}</p>
                <p class="info"><span class="date">${date}</span><span class="category">${category}</span></p>
            </a>
        `;
    item.innerHTML = src;

    return item;
  }

  loadLifeAtTier4Thumbnails(){
    let urlList = this.lifeAtTier4ThumbnailAccessAPIPathList;
    let promiseList = [];
    this.lifeAtTier4thumbnailPathList = [];

    urlList.forEach((url, i) => {
      promiseList.push(
        fetch(url).then((res) => {
          return res.json();
        }).then((res) => {
          this.lifeAtTier4thumbnailPathList[i] = res;
        })
      );
    });

    Promise
      .all(promiseList)
      .then(() => {
        // trace("life at tier4 thumbnail path loaded", this.lifeAtTier4thumbnailPathList);
        this.setLifeAtTier4Thumbnails();
      });
   }

  setLifeAtTier4Thumbnails() {
    let protocol = location.protocol;
    let imgs = document.querySelectorAll('main.ourteam > .section7 .section_child .img img');
    for (let i = 0, len = this.lifeAtTier4thumbnailPathList.length; i < len; i++) {
      let fields = this.lifeAtTier4thumbnailPathList[i].fields;
      if(fields){
        let thumbURL = protocol + fields.file.url;
        imgs[i].src = thumbURL;
      }
    }
  }


/*  getHTML(value) {
    /!* pug src
    .person.carousel_item
        .thumb
            img(src="/assets/img/ourteam/sec2_1.jpg" alt="")
        p.name Dummy Name
        p.position POSITION
     *!/

    let name = value.name;
    let position = value.position;
    let font2 = (this.pack.LANG === "en") ? "" : "font2";

    return `
            <div class="person carousel_item">
                <div class="thumb"><img src="" alt="" loading="lazy"></div>
                <p class="name ${font2}">${name}</p>
                <p class="position ${font2}">${position}</p>
          </div>
        `;
  }*/

/*  setPeople() {
    let src = "";
    for (let i = 0, len = this.peopleList.length; i < len; i++) {
      let item = this.peopleList[i];
      let html = this.getHTML(item);
      src += html;
    }

    this.peopleContainer.innerHTML = src;

    if (!this.carouselOurPeople) this.carouselOurPeople = new Carousel();
    else this.carouselOurPeople.reset();
    this.carouselOurPeople.setTarget(this.ourPeopleCarouselTarget);
    this.carouselOurPeople.setMarginRightSP(true, 5.6);
    this.carouselOurPeople.setAdjustMinXSP(true, 5.6);
    this.carouselOurPeople.setOnlySP();
  }*/

/*
  loadPeopleThumbnails() {
    let urlList = this.peopleThumbnailAccessAPIPathList;
    let promiseList = [];
    this.thumbnailPathList = [];

    urlList.forEach((url, i) => {
      promiseList.push(
        fetch(url).then((res) => {
          return res.json();
        }).then((res) => {
          this.thumbnailPathList[i] = res;
        })
      );
    });

    Promise
      .all(promiseList)
      .then(() => {
        trace("our team thumbnail path loaded", this.thumbnailPathList);
        this.setThumbnails();
      });
  }
*/

/*
  setThumbnails() {
    let protocol = location.protocol;
    let imgs = document.querySelectorAll('main.ourteam > .section2 .section_child .thumb img');
    for (let i = 0, len = this.thumbnailPathList.length; i < len; i++) {
      let thumbURL = protocol + this.thumbnailPathList[i].fields.file.url;
      imgs[i].src = thumbURL;
    }
  }
*/

  scrollHandler() {
    this.pack.checkBG(this.next);
    this.pack.checkBottom() ? this.next.classList.add("show_fixed") : this.next.classList.remove("show_fixed");
    this.checkSection5();
  }


  checkSection5() {
    let y = this.section5Bottom.getBoundingClientRect().top;
    if (y <= this.sh && y >= 0) {
      let width, width2, borderRadius, difScale;
      let maxPer;
      if (this.sw >= this.pack.BP) {
        width = 90;
        borderRadius = 500;
        maxPer = (this.sw - 80) / this.sw * 100;    //左右に40px余白儲ける
      } else {
        width = 80;
        borderRadius = 500;
        maxPer = 100;
      }

      difScale = maxPer / width - 1;
      width2 = maxPer - width;

      let moveH = this.shh;
      let currentY = moveH - y;
      let normal = currentY / moveH;
      let currentScale = 1 + difScale * (1 - normal);

      if (y <= this.shh) {
        this.section5Bottom.style.width = width + width2 * normal + "vw";
        this.section5Bottom.style["border-radius"] = borderRadius - borderRadius * normal + "px";
        this.section5BottomPC.style.transform = "scale(" + currentScale + ")";
        this.section5BottomSP.style.transform = "scale(" + currentScale + ")";
      } else {
        currentScale = 1 + difScale;
        this.section5BottomPC.style.transform = "scale(" + currentScale + ")";
        this.section5BottomSP.style.transform = "scale(" + currentScale + ")";
      }
    }
  }

  enterframe() {

  }

  enterframeThinOut() {
    if(this.carouselPickup) this.carouselPickup.enterframeThinOut();
    if(this.carouselLifeAtTier4) this.carouselLifeAtTier4.enterframeThinOut();
  }

  executeResize() {
    super.executeResize();
  }
}
